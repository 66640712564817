import React, { Component } from "react";
import {  Card, CardBody, CardHeader, Col, Row, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, } from 'reactstrap';


const selectcolor = '#e99511';
const noselectcolor = '#FFF'
const buttonname = "SELECT"


const questname = "Understanding what drives purchase decisions"
const questexplain = "A core element of what makes Pricekit so useful is that it is designed to help you understand the optimal mix of features at different price points."
const questexplain2 = "One of the primary ways to accomplish this is through discrete choice surveys.  Respondents are presented with a rotating set of options to choose from."
const questexplain3 = "You may have many different feature combinations and price points, so we smartly determine which set, what price and the sequence to show each respondent."
const questexplain5 = "The end result is a model which shows the features that most drive purchase decisions and insights into the optimal price points for each customer segment."

const newimg1 = "../assets/img/tour-honey-5.jpg"
const newimg2 = "../assets/img/tour-honey-6.jpg"
const newimg3 = "../assets/img/tour-honey-7.jpg"

const newoptionheader1 = "All American"
const newoptionheader2 = "Caliente!"
const newoptionheader3 = "Super-Lux"


const newoption1_listheader1 = "Flavor"
const newoption1_listdetail1 = "Lip smackin' good."
const newoption1_listheader2 = "Size"
const newoption1_listdetail2 = "18 oz."
const newoption1_listheader3 = "Character Impact"
const newoption1_listdetail3 = "+1 Strength, -1 Perception"
const newoption1_price = "$7.99"


const newoption2_listheader1 = "Flavor"
const newoption2_listdetail1 = "More chile than honey."
const newoption2_listheader2 = "Size"
const newoption2_listdetail2 = "4 oz."
const newoption2_listheader3 = "Character Impact"
const newoption2_listdetail3 = "+1 Strength, -2 Charisma"
const newoption2_price = "$7.99"


const newoption3_listheader1 = "Flavor"
const newoption3_listdetail1 = "100% Sourwood"
const newoption3_listheader2 = "Size"
const newoption3_listdetail2 = "4 oz."
const newoption3_listheader3 = "Character Impact"
const newoption3_listdetail3 = "+1 Dexterity, -1 Endurance"
const newoption3_price = "$7.99"




export default class UXSurveyTour extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoading: true,
        selectmade: false,
        onetime: true,
        try: "Try it:",
        choice1: false,
        select1: noselectcolor,
        choice2: false,
        select2: noselectcolor,
        choice3: false,
        select3: noselectcolor,
        choice4: false,
        select4: noselectcolor,
        choice5: false,
        select5: noselectcolor,
        img1: "../assets/img/tour-honey-1.jpg",
        img2: "../assets/img/tour-honey-2.jpg",
        img3: "../assets/img/tour-honey-3.jpg",
        img4: "../assets/img/tour-honey-4.jpg",
        optionheader1: "Kid's Choice",
        optionheader2: "Ultra Organic",
        optionheader3: "Xtreme Heat",
        optionheader4: "Bulk Buy",
        option1_listheader1: "Flavor",
        option1_listdetail1: "The 70% corn syrup really shines through.",
        option1_listheader2: "Size",
        option1_listdetail2: "8 oz.",
        option1_listheader3: "Character Impact",
        option1_listdetail3: "+1 Charisma, -1 Focus, -1 Cleanliness",
        option1_price: "$3.99",
        option2_listheader1: "Flavor",
        option2_listdetail1: "Slight essence of hand-cultivated clover pollen.",
        option2_listheader2: "Size",
        option2_listdetail2: "4 oz.",
        option2_listheader3: "Character Impact",
        option2_listdetail3: "+2 Pretentiousness, -2 Charisma",
        option2_price: "$7.99",
        option3_listheader1: "Flavor",
        option3_listdetail1: " A tounge-scorching 20,000 on the Scoville scale.",
        option3_listheader2: "Size",
        option3_listdetail2: "8 oz.",
        option3_listheader3: "Character Impact",
        option3_listdetail3: "+2 Strength, -1 Endurance",
        option3_price: "$3.99",
        option4_listheader1: "Flavor",
        option4_listdetail1: "What it lacks in taste it makes up in volume.",
        option4_listheader2: "Size",
        option4_listdetail2: "1 gallon (128 oz.).",
        option4_listheader3: "Character Impact",
        option4_listdetail3: "-2 Dexterity, +1 Endurance",
        option4_price: "$23.99",
      };
    }
   
  async componentDidMount() {
    this.setState({ isLoading: false });
  }

  toggleSelect(s, t){
    
    if(s===1){
          this.setState({
              selectmade: true,
              choice1: true,
              select1: selectcolor,
              choice2: false,
              select2: noselectcolor,
              choice3: false,
              select3: noselectcolor,
              choice4: false,
              select4: noselectcolor,
              choice5: false,
              select5: noselectcolor
            })
          }
      
    if(s===2){
        this.setState({
            selectmade: true,
            choice1: false,
            select1: noselectcolor,
            choice2: true,
            select2: selectcolor,
            choice3: false,
            select3: noselectcolor,
            choice4: false,
            select4: noselectcolor,
            choice5: false,
            select5: noselectcolor
          })
        }
        
    if(s===3){
        this.setState({
            selectmade: true,
            choice1: false,
            select1: noselectcolor,
            choice2: false,
            select2: noselectcolor,
            choice3: true,
            select3: selectcolor,
            choice4: false,
            select4: noselectcolor,
            choice5: false,
            select5: noselectcolor
          })
        }

    if(s===4){
        this.setState({
            selectmade: true,
            choice1: false,
            select1: noselectcolor,
            choice2: false,
            select2: noselectcolor,
            choice3: false,
            select3: noselectcolor,
            choice4: true,
            select4: selectcolor,
            choice5: false,
            select5: noselectcolor
          })
        }

    if(s===5){
      this.setState({
          selectmade: true,
          choice1: false,
          select1: noselectcolor,
          choice2: false,
          select2: noselectcolor,
          choice3: false,
          select3: noselectcolor,
          choice4: false,
          select4: noselectcolor,
          choice5: true,
          select5: selectcolor
        })
      }

      return
  }

  updateChoices(counter){

    if (this.state.choice1===true){
        const newimg4 = this.state.img1
        const newoptionheader4 = this.state.optionheader1
        const newoption4_listheader1 = this.state.option1_listheader1
        const newoption4_listdetail1 = this.state.option1_listdetail1
        const newoption4_listheader2 = this.state.option1_listheader2
        const newoption4_listdetail2 = this.state.option1_listdetail2
        const newoption4_listheader3 = this.state.option1_listheader3
        const newoption4_listdetail3 = this.state.option2_listdetail3
        const newoption4_price = "$4.99"
        this.setState({
            img4: newimg4,
            optionheader4: newoptionheader4,
            option4_listheader1: newoption4_listheader1,
            option4_listdetail1: newoption4_listdetail1,
            option4_listheader2: newoption4_listheader2,
            option4_listdetail2: newoption4_listdetail2,
            option4_listheader3: newoption4_listheader3,
            option4_listdetail3: newoption4_listdetail3,
            option4_price: newoption4_price,
        })
    }
    if (this.state.choice2===true){
        const newimg4 = this.state.img2
        const newoptionheader4 = this.state.optionheader2
        const newoption4_listheader1 = this.state.option2_listheader1
        const newoption4_listdetail1 = this.state.option2_listdetail1
        const newoption4_listheader2 = this.state.option2_listheader2
        const newoption4_listdetail2 = this.state.option2_listdetail2
        const newoption4_listheader3 = this.state.option2_listheader3
        const newoption4_listdetail3 = this.state.option2_listdetail3
        const newoption4_price = "$6.99"
        this.setState({
            img4: newimg4,
            optionheader4: newoptionheader4,
            option4_listheader1: newoption4_listheader1,
            option4_listdetail1: newoption4_listdetail1,
            option4_listheader2: newoption4_listheader2,
            option4_listdetail2: newoption4_listdetail2,
            option4_listheader3: newoption4_listheader3,
            option4_listdetail3: newoption4_listdetail3,
            option4_price: newoption4_price,
        })
    }
    if (this.state.choice3===true){
        const newimg4 = this.state.img3
        const newoptionheader4 = this.state.optionheader3
        const newoption4_listheader1 = this.state.option3_listheader1
        const newoption4_listdetail1 = this.state.option3_listdetail1
        const newoption4_listheader2 = this.state.option3_listheader2
        const newoption4_listdetail2 = this.state.option3_listdetail2
        const newoption4_listheader3 = this.state.option3_listheader3
        const newoption4_listdetail3 = this.state.option3_listdetail3
        const newoption4_price = "$4.99"
        this.setState({
            img4: newimg4,
            optionheader4: newoptionheader4,
            option4_listheader1: newoption4_listheader1,
            option4_listdetail1: newoption4_listdetail1,
            option4_listheader2: newoption4_listheader2,
            option4_listdetail2: newoption4_listdetail2,
            option4_listheader3: newoption4_listheader3,
            option4_listdetail3: newoption4_listdetail3,
            option4_price: newoption4_price,
        })
    }
    if (this.state.choice4===true){
        const newimg4 = this.state.img4
        const newoptionheader4 = this.state.optionheader4
        const newoption4_listheader1 = this.state.option4_listheader1
        const newoption4_listdetail1 = this.state.option4_listdetail1
        const newoption4_listheader2 = this.state.option4_listheader2
        const newoption4_listdetail2 = this.state.option4_listdetail2
        const newoption4_listheader3 = this.state.option4_listheader3
        const newoption4_listdetail3 = this.state.option4_listdetail3
        const newoption4_price = "$19.99"
        this.setState({
            img4: newimg4,
            optionheader4: newoptionheader4,
            option4_listheader1: newoption4_listheader1,
            option4_listdetail1: newoption4_listdetail1,
            option4_listheader2: newoption4_listheader2,
            option4_listdetail2: newoption4_listdetail2,
            option4_listheader3: newoption4_listheader3,
            option4_listdetail3: newoption4_listdetail3,
            option4_price: newoption4_price,
        })
    }
    this.setState({
        onetime: false,
        try: "Select again:",
        selectmade: false,
        choice1: false,
        select1: noselectcolor,
        choice2: false,
        select2: noselectcolor,
        choice3: false,
        select3: noselectcolor,
        choice4: false,
        select4: noselectcolor,
        img1: newimg1,
        img2: newimg2,
        img3: newimg3,
        optionheader1: newoptionheader1,
        optionheader2: newoptionheader2,
        optionheader3: newoptionheader3,
        option1_listheader1: newoption1_listheader1,
        option1_listdetail1: newoption1_listdetail1,
        option1_listheader2: newoption1_listheader2,
        option1_listdetail2: newoption1_listdetail2,
        option1_listheader3: newoption1_listheader3,
        option1_listdetail3: newoption1_listdetail3,
        option1_price: newoption1_price,
        option2_listheader1: newoption2_listheader1,
        option2_listdetail1: newoption2_listdetail1,
        option2_listheader2: newoption2_listheader2,
        option2_listdetail2: newoption2_listdetail2,
        option2_listheader3: newoption2_listheader3,
        option2_listdetail3: newoption2_listdetail3,
        option2_price: newoption2_price,
        option3_listheader1: newoption3_listheader1,
        option3_listdetail1: newoption3_listdetail1,
        option3_listheader2: newoption3_listheader2,
        option3_listdetail2: newoption3_listdetail2,
        option3_listheader3: newoption3_listheader3,
        option3_listdetail3: newoption3_listdetail3,
        option3_price: newoption3_price,
        });


}
    thankYou(){
        this.setState({try:"Thank You", selectmade: false, onetime: true})
    }

  render(){
 
    return(  

        <div>
            <div>

            <div className='tour-question-header'>
                <h2>{questname}</h2>
                <h3 style={{marginBottom:"10px"}}>{questexplain}</h3>
                <h4>{questexplain2}</h4>
                <h4>{questexplain3}</h4>
            </div>

            <div className='survey-question-body'>
            <div>
                <Row>
            <p style={{marginTop:"10px", marginLeft:"10px", color:"#20a8d8"}}>{this.state.try}</p>
            {this.state.selectmade && this.state.onetime && <button className="btn btn-pill btn-lg btn-outline-primary" style={{marginLeft:"5px", marginBottom: "10px"}} onClick={() => this.updateChoices()}>SUBMIT SELECTION</button>}
            {this.state.selectmade && !this.state.onetime && <button className="btn btn-pill btn-lg btn-outline-primary" style={{marginLeft:"5px", marginBottom: "10px"}} onClick={() => this.thankYou()}>COMPLETE</button>}
            </Row>

            <Row>

              <Col md="3">

              <Card>
              
              <CardHeader className='survey-card-header'>{this.state.optionheader1}</CardHeader>
              
              <CardBody style={{backgroundColor: this.state.select1 }} >            
                <img width="100%" src={this.state.img1} alt="Survey" />
 
                <ListGroup className='tour-card-listgroup'>
                  <ListGroupItem>
                      <ListGroupItemHeading>{this.state.option1_listheader1}</ListGroupItemHeading>
                      <ListGroupItemText>{this.state.option1_listdetail1}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                      <ListGroupItemHeading>{this.state.option1_listheader2}</ListGroupItemHeading>
                      <ListGroupItemText>{this.state.option1_listdetail2}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                      <ListGroupItemHeading>{this.state.option1_listheader3}</ListGroupItemHeading>
                      <ListGroupItemText>{this.state.option1_listdetail3}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                      <ListGroupItemHeading style={{fontSize:"150%", fontWeight:"600"}}>{this.state.option1_price}</ListGroupItemHeading>
                  </ListGroupItem>
                  </ListGroup>
              
              </CardBody>

              </Card>
              
              <button className="btn btn-pill btn-lg btn-outline-dark" style={{marginBottom:"20px"}} onClick={() => this.toggleSelect(1)}>{buttonname}</button>
              
              </Col>

              <Col md="3">

              <Card>
              
              <CardHeader className='survey-card-header'>{this.state.optionheader2}</CardHeader>
              
              <CardBody style={{backgroundColor: this.state.select2 }} > 
                <img width="100%" src={this.state.img2} alt="Survey" />
 
                <ListGroup className='tour-card-listgroup'>
                  <ListGroupItem>
                      <ListGroupItemHeading>{this.state.option2_listheader1}</ListGroupItemHeading>
                      <ListGroupItemText>{this.state.option2_listdetail1}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                      <ListGroupItemHeading>{this.state.option2_listheader2}</ListGroupItemHeading>
                      <ListGroupItemText>{this.state.option2_listdetail2}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                      <ListGroupItemHeading>{this.state.option2_listheader3}</ListGroupItemHeading>
                      <ListGroupItemText>{this.state.option2_listdetail3}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                      <ListGroupItemHeading style={{fontSize:"150%", fontWeight:"600"}}>{this.state.option2_price}</ListGroupItemHeading>
                  </ListGroupItem>
                  </ListGroup>
              
              </CardBody>
              
              </Card>
              
              <button className="btn btn-pill btn-lg btn-outline-dark" style={{marginBottom:"20px"}} onClick={() => this.toggleSelect(2)}>{buttonname}</button>
              
              </Col >

              <Col md="3">

              <Card>

              <CardHeader className='survey-card-header'>{this.state.optionheader3}</CardHeader>

              <CardBody style={{backgroundColor: this.state.select3 }} >            
                <img width="100%" src={this.state.img3} alt="Survey" />

                <ListGroup className='tour-card-listgroup'>
                  <ListGroupItem>
                      <ListGroupItemHeading>{this.state.option3_listheader1}</ListGroupItemHeading>
                      <ListGroupItemText>{this.state.option3_listdetail1}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                      <ListGroupItemHeading>{this.state.option3_listheader2}</ListGroupItemHeading>
                      <ListGroupItemText>{this.state.option3_listdetail2}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                      <ListGroupItemHeading>{this.state.option3_listheader3}</ListGroupItemHeading>
                      <ListGroupItemText>{this.state.option3_listdetail3}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                      <ListGroupItemHeading style={{fontSize:"150%", fontWeight:"600"}}>{this.state.option3_price}</ListGroupItemHeading>
                  </ListGroupItem>
                  </ListGroup>

              </CardBody>

              </Card>

              <button className="btn btn-pill btn-lg btn-outline-dark" style={{marginBottom:"20px"}} onClick={() => this.toggleSelect(3)}>{buttonname}</button>

              </Col>

              <Col md="3">

              <Card>
              
              <CardHeader className='survey-card-header'>{this.state.optionheader4}</CardHeader>
              
              <CardBody style={{backgroundColor: this.state.select4 }} >            
                <img width="100%" src={this.state.img4} alt="Survey" />
 
                <ListGroup className='tour-card-listgroup'>
                  <ListGroupItem>
                      <ListGroupItemHeading>{this.state.option4_listheader1}</ListGroupItemHeading>
                      <ListGroupItemText>{this.state.option4_listdetail1}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                      <ListGroupItemHeading>{this.state.option4_listheader2}</ListGroupItemHeading>
                      <ListGroupItemText>{this.state.option4_listdetail2}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                      <ListGroupItemHeading>{this.state.option4_listheader3}</ListGroupItemHeading>
                      <ListGroupItemText>{this.state.option4_listdetail3}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                      <ListGroupItemHeading style={{fontSize:"150%", fontWeight:"600"}}>{this.state.option4_price}</ListGroupItemHeading>
                  </ListGroupItem>
                  </ListGroup>
              
              </CardBody>
              
              </Card>
              
              <button className="btn btn-pill btn-lg btn-outline-dark" style={{marginBottom:"20px"}} onClick={() => this.toggleSelect(4)}>{buttonname}</button>
              
              </Col>

            </Row>
            </div>
            </div>
            <div className='tour-question-header'>
                <h3 style={{marginTop:"10px"}}>{questexplain5}</h3>
            </div>
            </div>
        </div>
    );
  }
}