import React, { Component } from "react";
import UXAnnotation from '../survey/UXAnnotation'
import SurveyChoice from '../survey/SurveyChoice'
import SurveyAutoChoice from '../survey/SurveyAutoChoice'
import SurveyList from '../survey/SurveyList'
import SurveyCard from '../survey/SurveyCard'
import SurveyWinners from '../survey/SurveyWinners'
import ReactTooltip from 'react-tooltip'
import SurveyNotLive from "../survey/SurveyNotLive"

/// SurveyQuestion - rendered by SurveyPage to determine which component type use
/// creates the various props to pass to different components;  mostly just a pass thru
/// for the specific question pages and the survey page which controls flow
/// currently only shows one question per page, but this was established in case
/// we needed multiple questions and a place to handle validation logic
///

const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

export default class SurveyQuestion extends Component {
  constructor(props) {
    super(props);
    this.focusDiv = React.createRef()
    let ismobile = false
    if (vw<1000){ismobile = true}
    this.handlePageValid = this.handlePageValid.bind(this)
    this.state = {
      ismobile: ismobile,
      };
    }
   
componentDidMount() {
  }

  handlePageValid(v){
    this.props.onPageValid(v)
  }


  getQuestion(questiontype){

    if(this.props.surveystatus !== "RESPONDENT COMPLETE"){

      if(questiontype === 'SurveyChoice'){
          return(<SurveyChoice 
            key={this.props.pageprops.thispage} 
            surveyprops={this.props.surveyprops}
            options={this.props.question.cards} 
            questionprops={this.props.questionprops} 
            questionId={this.props.question.questionId}
            savedpages={this.props.savedpages}
            savedresponses={this.props.savedresponses}
            saveSurvey={this.props.saveSurvey}
            handlePageValid={this.handlePageValid}
            isMobile={this.props.isMobile}
            />)}

      if(questiontype === 'AutoChoice'){

            return(<SurveyAutoChoice 
              key={this.props.pageprops.thispage} 
              surveyprops={this.props.surveyprops}
              pageprops={this.props.pageprops}
              options={this.props.question.cards} 
              questionprops={this.props.questionprops} 
              questionId={this.props.question.questionId}
              savedpages={this.props.savedpages}
              savedresponses={this.props.savedresponses}
              saveSurvey={this.props.saveSurvey}
              saveCards={this.props.saveCards}
              handlePageValid={this.handlePageValid}
              isMobile={this.props.isMobile}
              />)}
      
      if(questiontype === 'ChoiceWinners'){

        return(<SurveyWinners 
          key={this.props.pageprops.thispage} 
          surveyprops={this.props.surveyprops}
          pageprops={this.props.pageprops}
          options={this.props.question.cards} 
          questionprops={this.props.questionprops} 
          questionId={this.props.question.questionId}
          savedpages={this.props.savedpages}
          savedresponses={this.props.savedresponses}
          allresponses={this.props.allresponses}
          saveSurvey={this.props.saveSurvey}
          saveCards={this.props.saveCards}
          handlePageValid={this.handlePageValid}
          isMobile={this.props.isMobile}
          />)}


      if(questiontype === 'DesignCard'){
          return(<UXAnnotation 
            key={this.props.pageprops.thispage}
            surveyprops={this.props.surveyprops}
            options={this.props.question.cards} 
            questionprops={this.props.questionprops} 
            questionId={this.props.question.questionId}
            savedpages={this.props.savedpages}
            savedresponses={this.props.savedresponses}
            saveSurvey={this.props.saveSurvey}
            handlePageValid={this.handlePageValid}
            isMobile={this.props.isMobile}
            />)}

      if(questiontype === 'SurveyList'){
        return(<SurveyList 
          key={this.props.pageprops.thispage}
          surveyprops={this.props.surveyprops}
          list={this.props.question.list} 
          questionprops={this.props.questionprops} 
          questionId={this.props.question.questionId}
          savedpages={this.props.savedpages}
          savedresponses={this.props.savedresponses}
          saveSurvey={this.props.saveSurvey}
          handlePageValid={this.handlePageValid}
          isMobile={this.props.isMobile}
          />)}

        if(questiontype === 'SurveyCard'){
          return(<SurveyCard 
            key={this.props.pageprops.thispage}
            surveyprops={this.props.surveyprops}
            cards={this.props.question.cards} 
            questionprops={this.props.questionprops} 
            questionId={this.props.question.questionId}
            savedpages={this.props.savedpages}
            savedresponses={this.props.savedresponses}
            saveSurvey={this.props.saveSurvey}
            handlePageValid={this.handlePageValid}
            isMobile={this.props.isMobile}
            />)}
        
        } else {

          return(
          <SurveyNotLive
            surveystatus={this.props.surveystatus}
          />
          )

        }
          

    }

/// setting a ref on the title div so it can get focus on mobile device

  render(){
    return(
    
    <div>
      <ReactTooltip />
    {this.props.surveystatus !== "RESPONDENT COMPLETE" &&
    <div className = "survey-question-headings" 
    style={{color:this.props.surveyprops.style.questionheadtextcolor}}
    >

        <div style={{display:"flex"}}>
          
          {this.props.pageprops.showquestiontitle && !this.props.isMobile &&
          <h1>{this.props.pageprops.questiontitle}</h1>
          }

          {this.props.pageprops.showquestiontitle && this.props.isMobile &&
          <h1 style={{fontSize:"1.2em"}}>{this.props.pageprops.questiontitle}</h1>
          }


          {this.props.pageprops.showquestiontip &&
          <a className="fa fa-info-circle" style={{fontSize:"0.8em", marginLeft:"auto", marginRight:"10px"}} data-place="left" data-tip={this.props.pageprops.questiontip}/>
          }
          </div>
          {this.props.pageprops.showquestionsubtitle &&
        <h4>{this.props.pageprops.questionsubtitle}</h4>
          }
    
    </div>
    }


        {this.getQuestion(this.props.questiontype)}

    
     </div>
    );
  }
}