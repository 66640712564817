import React, { Component } from "react";
import { Card, CardBody, CardHeader,  Col,  Row, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, } from 'reactstrap';
import './UXAnnotation.scss';
import Annotation from 'react-image-annotation';



const questname = "Get direct feedback on your feature designs."
const questexplain = "Pricekit enables customers to directly add comments on your designs."
const questexplain2 = "This direct interaction provides color and clarity that simple answers and comments can't provide."
const questexplain3 = "You can also add your own call-outs to the image to direct users to the specific areas you are interested in."

const optionheader1 = "Dashboard Redesign"
const option1_listheader1 = "New Reporting Widgets."
const option1_listdetail1 = "Out of the box graphs for at-a-glance view of most recent activity.  Plus a new set of sort/filters."


const img = "../assets/img/pricekit-dashboard.jpg"

export default class UXAnnotationTour extends Component {
  state = {
    annotations: [{"geometry":{"type":"RECTANGLE","x":90,"y":34,"width":7.4,"height":10.3},"data":{"text":"Not sure what this option is for","id":0.999}}],
    annotation: {},

  }

  onChange = (annotation) => {
    this.setState({ annotation })
  }

  onSubmit = (annotation) => {
    const { geometry, data } = annotation

    this.setState({
      annotation: {},
      annotations: this.state.annotations.concat({
        geometry,
        data: {
          ...data,
          id: Math.random()
        }
      })
    })
  }

  render () {
    return (
      <div>

<div className='tour-question-header'>
                <h2>{questname}</h2>
                <h3 style={{marginBottom:"10px"}}>{questexplain}</h3>
                <h4>{questexplain2}</h4>
                <h4>{questexplain3}</h4>
            </div>

            <div className='survey-question-body'>
            <p className='annotation-helper'>Click and drag on the image to try it:</p>

            <Row>

              <Col>

              <Card>
              
              <CardHeader className='survey-card-header' style={{color:"black"}}>{optionheader1}</CardHeader>
              
              <CardBody style={{color:"black"}}>     
                       
              <ListGroup className='tour-card-listgroup' style={{color:"black", marginBottom:"20px"}}>
                  <ListGroupItem>
                      <ListGroupItemHeading>{option1_listheader1}</ListGroupItemHeading>
                      <ListGroupItemText>{option1_listdetail1}</ListGroupItemText>
                  </ListGroupItem>
                  </ListGroup>

              <Annotation
                src={img}
                alt='image to be annotated by respondent'

                annotations={this.state.annotations}

                type={this.state.type}
                value={this.state.annotation}
                onChange={this.onChange}
                onSubmit={this.onSubmit}

                />
 
              </CardBody>

              </Card>
              
              </Col>

              </Row>
      </div>
      </div>
    )
  }
}