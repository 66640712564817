import React, { Component } from "react";
import { Container } from 'reactstrap';
import { API } from "aws-amplify";
import Loader from "react-loader-spinner";
import './Survey.scss';
import ReactGA from 'react-ga';
import SurveyPage from "../survey/SurveyPage"
import SurveyNotLive from "../survey/SurveyNotLive"
import Cookies from 'universal-cookie';

/// Gets the survey from AWS based on querystring params - passed in as a prop
/// sends to survey page which does flow control
/// this survey component will send the page requested

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

function isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};

/// will want to update cookies to be a list, right now it is just the value of last COMPLETED SURVEY

const cookies = new Cookies();
let takensurveys = cookies.get('pricekit');


export default class Survey extends Component {
  constructor(props) {
    super(props);
    let today = new Date();
    let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let dateTime = date+' '+time;
    this.changePage = this.changePage.bind(this)
    let isMobile = isMobileDevice()

    console.log('--->',takensurveys)
    let isFirstTime = true
    if (takensurveys === this.props.surveyId){
        isFirstTime = false
    }

    this.state = {
        isLoading: true,
        isFirstTime: isFirstTime,
        isMobile: isMobile,
        vw: vw,
        vh: vh,
        userId: uuidv4(),
        responseId: uuidv4(),
        surveystart: dateTime,
        currentpageindex: 0,
        currentpage: null,
        pageflow: null,
        pageorder: null,
        pages:null,
        surveyprops:null,
        currentquestions: null,
        currentpageprops: null,
        survey: null,
        surveystatus:null,
      };
    }
   
    async componentDidMount() {
            ReactGA.pageview('/survey')
            let surveystatus = "None"

            window.history.pushState(null, document.title, window.location.href);
            window.addEventListener('popstate', function (event){
                window.history.pushState(null, document.title,  window.location.href);
            });

            try {
                let querystring = {'queryStringParameters':{'orgId': "None", 'querytype':this.props.surveyId, 'isMobile':this.state.isMobile, 'vw':this.state.vw, 'vh': this.state.vh}}
                let surveyresponse = {}
                let singleresponse = false
                let passwordrequired = false
                let password = ""
                let surveyprops = {}
                let pages = {}
                let pageorder =[]
                surveyresponse = await this.getSurvey(querystring);
                surveyresponse = surveyresponse[0]
                surveystatus = surveyresponse['status']
                singleresponse = surveyresponse['singleresponse']
                passwordrequired = surveyresponse['passwordrequired']
                password = surveyresponse['password']
                surveyprops = JSON.parse(surveyresponse['surveyProps'])
                pages = JSON.parse(surveyresponse['surveyPages'])
                pageorder = JSON.parse(surveyresponse['surveyPageOrder'])
                surveystatus = surveyprops['surveysettings']['status']

                let delsurveypropspage = delete pages['SurveyProps']
                if(pageorder[0]['pageId']==="SurveyProps"){
                    pageorder.splice(0,1)
                }
                let firstpage = pageorder[0]['pageId']
                let isFirst = pageorder[0]['isFirst']
                let isLast = pageorder[0]['isLast']


            this.setState({ 
                isLoading: false,
                survey: surveyresponse,
                surveyprops: surveyprops,
                pageorder: pageorder,
                pages: pages,
                pageflow: pageorder,
                currentpage: firstpage,
                isFirst: isFirst,
                isLast: isLast,
                surveystatus:surveystatus,
                currentquestions: pages[firstpage].questions, 
                currentpageprops: pages[firstpage].pageprops,
            });
        } catch (e) {
            surveystatus = "Not Found"
            this.setState({surveystatus:surveystatus, survey:"None"})
        }

      }
    

        getSurvey(querystring) {
            return API.get("survey", "/survey", querystring);
            }

        changePage(c){

            if (c==="B"){
                this._div.scrollTop = 0
                let newpage = this.state.currentpageindex - 1
                if(newpage<0){newpage = 0}
                let pageId = this.state.pageorder[newpage].pageId
                let isFirst = this.state.pageorder[newpage].isFirst
                let isLast = this.state.pageorder[newpage].isLast
                this.setState({
                    currentpageindex: Number(newpage),
                    currentpage: pageId,
                    page:  this.state.pages[pageId],
                    isFirst: isFirst,
                    isLast: isLast,
                    currentquestions: this.state.pages[pageId].questions,
                    currentpageprops: this.state.pages[pageId].pageprops
                })
            } 

            if (c==="F"){
                this._div.scrollTop = 0
                let newpage = this.state.currentpageindex + 1
                if(newpage===this.state.pageorder.length-1){newpage = this.state.pageorder.length-1}
                let pageId = this.state.pageorder[newpage].pageId
                let isFirst = this.state.pageorder[newpage].isFirst
                let isLast = this.state.pageorder[newpage].isLast
                this.setState({
                    currentpageindex: Number(newpage),
                    currentpage: pageId,
                    page:  this.state.pages[pageId],
                    isFirst: isFirst,
                    isLast: isLast,                   
                    currentquestions: this.state.pages[pageId].questions,
                    currentpageprops: this.state.pages[pageId].pageprops
                })
        }

            if(c==="COMPLETE"){
                cookies.set('pricekit', this.props.surveyId, { path: '/' })
                this.setState({surveystatus:"RESPONDENT COMPLETE"})
            }

        }


        showLoader(){
            return(
            <div className='survey-loader'>
                <Loader type="Puff" color="#20a8d8" height={100} width={100} timeout={3000}/>
            </div>
            )
        }


       /// saves the auto cards after first time generating them
       saveCards= updatecards => {

        let bigcopy = JSON.parse(JSON.stringify(this.state.pages[this.state.currentpage].questions))

        bigcopy[0]['cards'] = updatecards

        this.setState(prevState => ({
            currentquestions:bigcopy,
            page: {...prevState.page,           
                questions:bigcopy},
            pages: {...prevState.pages,
                [this.state.currentpage]: {...prevState.pages[this.state.currentpage],
                    questions: bigcopy}},
            }))
    }


        showSurvey(s){

            if(["LIVE", "TEST", "RESPONDENT COMPLETE"].includes(s)){

                return(
                    <Container fluid>
                    <SurveyPage
                        surveyId={this.props.surveyId}
                        tracking={this.props.tracking}
                        userId={this.state.userId}
                        responseId={this.state.responseId}
                        surveystart={this.state.surveystart}
                        surveyprops ={this.state.surveyprops}
                        questions={this.state.currentquestions} 
                        pageprops={this.state.currentpageprops}
                        currentpage = {this.state.currentpage}
                        onPageChange={this.changePage}
                        saveCards={this.saveCards}
                        isFirst = {this.state.isFirst}
                        isLast ={this.state.isLast}
                        surveystatus={this.state.surveystatus}
                        isMobile={this.state.isMobile}
                    />
                    </Container>
                )
            
            } else {
                return(

                    <SurveyNotLive
                        surveystatus={s}
                    />

                )
            
        }
        }



  render(){
    return(  
      <div className="survey-wrapper"
      ref={(ref) => this._div = ref}
      > 
        {this.state.survey ? this.showSurvey(this.state.surveystatus) : this.showLoader()}

      </div>


    );
  }
}