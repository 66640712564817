import React, { Component } from "react";
import { Button, Card, CardBody, CardImg, CardTitle, CardText, CardHeader, CardGroup, CardSubtitle, Col, Container, Row, CardDeck, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText} from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';

import './Home.scss';
import ReactGA from 'react-ga';


/// TODO - survey link;  privacy and about content


export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoading: true,
      };
    }
   
  async componentDidMount() {
    ReactGA.pageview('/home')
    this.setState({ isLoading: false });
  }

  render(){
    return(  
      <div className="wrapper">


        <div className="hero">
          <div className="hero-text">
          <h1>the fast, easy and accurate way</h1>
          <h3>to test concepts, create offers and optimize price</h3>
          <div className ="hero-button">
          <LinkContainer to="/tour">
            <button className="btn btn-pill btn-lg btn-outline-light">TAKE A TOUR</button>
          </LinkContainer>
          </div>
          </div>
        </div>

        <div className="section static-white">
          <div className="section-headline">
          <h1>practical problem solving</h1>
          <h2>real data for smart decisions</h2>
          <div className="section-body">
            <div>
            <Row>
              <Col md="4">
              <div>
              <h2>for product managers</h2>
              <h3><mark>optimize your pricing and feature packaging</mark> to boost adoption, ARR, ACV and upsell</h3>
              </div>
              </Col>
              <Col md="4">
              <div>
              <h2>for product designers </h2>
              <h3><mark>validate new concepts and UX options</mark> to focus engineering on high-confidence designs</h3>
              </div>
              </Col>
              <Col md="4">
              <div>
              <h2>for product marketers </h2>
              <h3><mark>get segment-specific feedback</mark> to create the right messaging, positioning and calls-to-action</h3>
              </div>
              </Col>
            </Row>
            </div>
          </div>
          </div>
        </div>

        <div className="section static-black">
        <div className="section-headline">
          <h1>designed for product people</h1>
          <h2>how pricekit works</h2>
          <div className="section-body">
            <div>
            <Row>
              <Col md="3">
              <Card>
              <CardHeader style={{ backgroundColor: '#333', Color: '#333', fontSize: "200%" }}>Create a Survey</CardHeader>
                <CardImg top width="100%" src="../assets/img/createasurvey.png" alt="Card image cap" />
                <CardBody style={{ backgroundColor: '#333', fontSize: "150%" }} > 
                  <CardTitle>Start with a pre-built template or one you've saved before</CardTitle>
                  <CardTitle>Add Screeners and Segment Indentifiers</CardTitle>
                  <CardTitle>Test, Validate and Publish Survey</CardTitle>
                </CardBody>
                </Card>
              </Col>
              <Col md="3">
              <Card>
              <CardHeader style={{ backgroundColor: '#333', Color: '#333', fontSize: "200%" }}>Choose an Audience</CardHeader>
                <CardImg top width="100%" src="../assets/img/chooseanaudience.png" alt="Card image cap" />
                <CardBody style={{ backgroundColor: '#333', fontSize: "150%" }} > 
                  <CardTitle>Upload an email list from your CRM</CardTitle>
                  <CardTitle>Post an add to target groups in Facebook, LinkedIn or Google</CardTitle>
                  <CardText>Send internally through email, Teams or Slack</CardText>
                </CardBody>
                </Card>
              </Col>
              <Col md="3">
              <Card>
              <CardHeader style={{ backgroundColor: '#333', Color: '#333', fontSize: "200%" }}>Run the Survey</CardHeader>
                <CardImg top width="100%" src="../assets/img/runthesurvey.png" alt="Card image cap" />
                <CardBody style={{ backgroundColor: '#333', fontSize: "150%" }} > 
                  <CardTitle>Participants take survey on any device</CardTitle>
                  <CardTitle>Questions and Choices are smartly allocated across population</CardTitle>
                  <CardText>Response criteria monitored</CardText>
                </CardBody>
                </Card>
              </Col>
              <Col md="3">
              <Card>
              <CardHeader style={{ backgroundColor: '#333', Color: '#333', fontSize: "200%" }}>Use the Results</CardHeader>
                <CardImg top width="100%" src="../assets/img/usetheresults.png" alt="Card image cap" />
                <CardBody style={{ backgroundColor: '#333', fontSize: "150%" }} > 
                  <CardTitle>Optionally enrich respondent profile with demographics</CardTitle>
                  <CardTitle>Dashboard and anlaytic models highlight best options </CardTitle>
                  <CardText>Download data or connect by API to update CRM</CardText>
                </CardBody>
                </Card>
              </Col>
            </Row>
            </div>
          </div>
          </div>
        </div>

        <div className="section static-white">
        <div className="section-headline">
          <h1>why pricekit ? </h1>
          <h2>because it's the best solution for discrete choice analysis</h2>
          <div className="section-body-lessbottommargin">
            <div>
            <Row>
            <Col md="12">
              <div className="section-detail">
              <h2>what this means for you</h2>
              <div>
              <h3>unlike basic tools like SurveyMonkey: you can test <mark>many options</mark> across all your survey takers, and the results are put into an <mark>analytic model</mark> and purpose-built dashboard.</h3>
              </div>
              <h3>unlike generic platforms like Qualtrics: you can get up and running <mark>fast</mark>, with <mark>less expense</mark> and no unwanted <mark>feature bloat</mark>.</h3>
              </div>
              <div className="hero-button">
                <LinkContainer to="/Tour">
                  <button className="btn btn-pill btn-lg btn-outline-dark">LEARN MORE</button>
                </LinkContainer>
              </div>
              </Col>
            </Row>
            </div>
          </div>
          </div>
        </div>

        <div className="section static-white">
        <div className="section-headline">
          <h1>plans</h1>
          <h2>an option for everybody</h2>
          <div className="section-body">
            <CardDeck>
              <Card>
                <CardBody>
                  <CardHeader>
                  <CardTitle><div className="display-4">FREE</div></CardTitle>
                  <CardSubtitle><h4>For evaluation and simple tests</h4></CardSubtitle>
                  </CardHeader>
                  <div style={{marginTop: "10px", marginBottom: "10px"}}>
                <ListGroup>
                    <ListGroupItem>
                        <ListGroupItemHeading>Single user and single active survey</ListGroupItemHeading>
                    </ListGroupItem>
                    <ListGroupItem>
                        <ListGroupItemHeading>Send your own invitations</ListGroupItemHeading>
                    </ListGroupItem>
                    <ListGroupItem>
                        <ListGroupItemHeading>Up to 30 responses per survey</ListGroupItemHeading>
                    </ListGroupItem>
                    </ListGroup>
                </div>
                </CardBody>
              </Card>
              <Card >
                <CardBody>
                  <CardHeader>
                  <CardTitle><div className="display-4">$99</div></CardTitle>
                  <CardSubtitle><h4>For a single important project</h4></CardSubtitle>
                  </CardHeader>
                  <div style={{marginTop: "10px", marginBottom: "10px"}}>
                <ListGroup>
                    <ListGroupItem>
                        <ListGroupItemHeading>One user, one survey, 3 month access</ListGroupItemHeading>
                    </ListGroupItem>
                    <ListGroupItem>
                        <ListGroupItemHeading>Unlimited responses</ListGroupItemHeading>
                    </ListGroupItem>
                    <ListGroupItem>
                        <ListGroupItemHeading>All question types and detailed analysis</ListGroupItemHeading>
                    </ListGroupItem>
                    </ListGroup>
                </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardHeader>
                  <CardTitle><div className="display-4">$49/mo</div></CardTitle>
                  <CardSubtitle><h4>For data-driven product people</h4></CardSubtitle>
                  </CardHeader>
                  <div style={{marginTop: "10px", marginBottom: "10px"}}>
                <ListGroup>
                    <ListGroupItem>
                        <ListGroupItemHeading>Single user and unlimited surveys</ListGroupItemHeading>
                    </ListGroupItem>
                    <ListGroupItem>
                        <ListGroupItemHeading>UX testing and custom instructions</ListGroupItemHeading>
                    </ListGroupItem>
                    <ListGroupItem>
                        <ListGroupItemHeading>Unlimited responses</ListGroupItemHeading>
                    </ListGroupItem>
                    </ListGroup>
                </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardHeader>
                  <CardTitle><div className="display-4">$249/mo</div></CardTitle>
                  <CardSubtitle><h4>For active enterprise teams </h4></CardSubtitle>
                  </CardHeader>
                  <div style={{marginTop: "10px", marginBottom: "10px"}}>
                <ListGroup>
                    <ListGroupItem>
                        <ListGroupItemHeading>Unlimited users and unlimited surveys</ListGroupItemHeading>
                    </ListGroupItem>
                    <ListGroupItem>
                        <ListGroupItemHeading>Deep profiling with external data</ListGroupItemHeading>
                    </ListGroupItem>
                    <ListGroupItem>
                        <ListGroupItemHeading>Integrations and API</ListGroupItemHeading>
                    </ListGroupItem>
                    </ListGroup>
                </div>
                </CardBody>
              </Card>
            </CardDeck>
            <div style={{marginTop:"20px"}}>
            <LinkContainer to="/FAQ">
                  <button className="btn btn-pill btn-lg btn-outline-dark">DIG INTO OUR FAQ</button>
            </LinkContainer>
            </div>
          </div>
          </div>
        </div>


        <div className="closer">
        <div className="hero-text">
          <h1>easy to make, easy to take</h1>
          <h3 style={{marginBottom:"20px"}}>see how we can help you test concepts, create offers and optimize price </h3>
          <LinkContainer to="/Contact">
                  <button className="btn btn-pill btn-lg btn-outline-light">SCHEDULE A DEMO</button>
            </LinkContainer>
          </div>
        </div>

      </div>
    );
  }
}