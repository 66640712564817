import React, { Component } from "react";
import { Card, CardBody, CardImg, CardTitle, CardText, CardHeader, CardFooter, CardGroup, CardSubtitle, Col, Row} from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import ChoiceCard from '../survey/ChoiceCard'



/// SurveyCard - the page component that handles choice questions that have media
/// each choice is on a card, and there are any number of cards, but not tested.  Practical limit is 6 
/// since each is in a col the real limit might be 12
/// each card uses the same selection logic, and there is only one used for each of the cards.
/// the constructor - either pulls in saved content (in case you are going back to a filled out page)
/// or initialize it with null/false - used for default input content and for validation

/// gets viewport width
const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);


function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;
  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

function pricetest(price, pricerange) {

    let n = Number(price)
    let r = Number(pricerange)
    r = r/100
  
    let remainder = n % 1
    remainder = remainder.toFixed(2)
    let posorneg = Math.floor(Math.random() * 3)
    if(posorneg === 0){posorneg = -1}
    if(posorneg === 1){posorneg = 0}
    if(posorneg === 2){posorneg = 1}
    let newn = n
  
    if(n<1){
      newn = n + (n * r * posorneg)
      newn= newn.toFixed(2)
      if(newn<0){
        newn = n.toFixed(2)}
    }
  
    if(n>=1){
      newn =  n + (n * r * posorneg)
      if(remainder===0){
        newn = Math.round(newn).toFixed(2)
      }
      if(remainder !==0){
        let newremainder = newn % 1
        newremainder = newremainder.toFixed(2)
        newn = Number(newn) - Number(newremainder)
        newn = Number(newn) + Number(remainder)
        newn = newn.toFixed(2)
      }
    }
  
    return newn
  }

export default class SurveyWinners extends Component {
  constructor(props) {
    super(props);

    let winners = []
    if(this.props.allresponses !== undefined){
        if(Object.keys(this.props.allresponses).length > 0){
            for (let pg of Object.keys(this.props.allresponses)){
                if(pg!== this.props.questionId){
                    if(this.props.allresponses[pg]['selectvalue'] !== undefined){
                        if(Object.keys(this.props.allresponses[pg]['selectvalue']).length > 0){
                            for (let sv of Object.keys(this.props.allresponses[pg]['selectvalue'])){
                                if (this.props.allresponses[pg]['selectvalue'][sv]==='selected'){
                                    winners.push(sv)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    let packagesperpage = winners.length

    let packagesonpage = []
    if(winners.length>0){
        for (let w of winners){
            for(let p of this.props.surveyprops.packageoptions){
                if(p['packageId']===w){
                    packagesonpage.push(JSON.parse(p['content']))
                }
            }
        }
    }

    
    let mwidth = 1
    if (packagesperpage>0){
        mwidth = 1/packagesperpage
    }
    mwidth = mwidth * 100
    mwidth = mwidth - 3
    mwidth = mwidth.toFixed()
    mwidth = mwidth + "%"
    if(this.props.isMobile){
      let divby3 = this.props.options.length % 3
      mwidth = "45%"
      if(divby3==0){
        mwidth = "30%"
      }
    }

    this.changeSelection = this.changeSelection.bind(this)
    this.changeAllocation = this.changeAllocation.bind(this)
    this.changeMaxDiff = this.changeMaxDiff.bind(this)
    this.toggleRadioCheck = this.toggleRadioCheck.bind(this)
    this.toggleLightbox = this.toggleLightbox.bind(this)

    let initialselections = {}
    let initialcolor = {}
    let initializefalse = ['checkbox', 'radio']
    let inititaltotal = 0
    let initialremaining = this.props.questionprops.allocationamount
    let initialoptions = this.props.options
    if (typeof this.props.savedpages != "undefined") {
        if(this.props.savedpages===true){
            initialselections = this.props.savedresponses['selectvalue']
            initialoptions = this.props.savedresponses['options']
            initialcolor = this.props.savedresponses['cardcolor']
            initialremaining = this.props.savedresponses['remaining']
            inititaltotal = this.props.savedresponses['total']
        }
        } else {
                for (let i of packagesonpage){
                    let radiocheck = {}
                    initialselections[i['id']] = null
                    if(this.props.questionprops.selectiontype==='toggle'){initialselections[i['id']] = false}
                    if(this.props.questionprops.selectiontype==='slider'){initialselections[i['id']] = this.props.questionprops['sliderdefault']}
                    if(this.props.questionprops.selectiontype==='range'){initialselections[i['id']] = this.props.questionprops['rangedefault']}
                    if(this.props.questionprops.selectiontype==='allocation'){initialselections[i['id']] = 0}
                    if(initializefalse.includes(this.props.questionprops.selectiontype))
                        {
                        for (let s of this.props.questionprops['selectoptions']){
                            radiocheck[s['value']]=false
                        }
                        initialselections[i['id']]=radiocheck
                        }
                    initialcolor[i['id']] = this.props.questionprops.noselectcolor
                }
                if(this.props.questionprops.randomize){ initialoptions = shuffle(this.props.options)}
            }
    
    this.props.saveCards(packagesonpage)

    this.state = {
        showImage: false,
        selectvalue: initialselections,
        options: initialoptions,
        cardcolor:initialcolor,
        remaining: initialremaining,
        total: inititaltotal,
        isvalid: false,
        mwidth: mwidth,
        selectiontype:this.props.questionprops.selectiontype,
        packagesonpage: packagesonpage,
        packagelist:this.props.surveyprops.packageoptions,
        pagetype:"ChoiceWinners"
      };
    }


    async componentDidMount() {
      let validcheck = false
      if(this.props.questionprops.validationtype==="none"){
        validcheck=true}
      else {
        for (let check in this.state.selectvalue){
          if(check !== null){
            validcheck = true
          }
        }
      }
      this.setState({isvalid: validcheck}, () => {this.props.handlePageValid(this.state.isvalid)})}


    /// saves state, including survey choices, on unmounting
    componentWillUnmount(){
        this.props.saveSurvey(this.props.questionId, this.state, this.props.questionprops)
    }


    toggleLightbox(img, title, caption){
      this.setState({showImage:!this.state.showImage, imageSrc:img, imageTitle:title, imageCaption:caption})
    }

    /// function to update state with a new selection, is passed in by Choice Card and
    /// this is also where the validation occurs.  i is id, e is value
    changeSelection(i, e){
        let validcheck = false
        let invaliditem = false
        if(this.props.questionprops.validationtype === "all"){
          for (let v in this.state.selectvalue){
            if (v===i){
              if(e===null){invaliditem = true}
            } else {
              if(this.state.selectvalue[v]===null){invaliditem = true}
            }
          }
        } else {
          if(e===null){invaliditem = true}
          else{validcheck=true}
        }
        if (!invaliditem){validcheck=true}
        if(this.props.questionprops.validationtype==="none"){validcheck=true}
        this.setState(prevState => ({selectvalue: {...prevState.selectvalue, [i]: e}}))
        this.setState({isvalid: validcheck}, () => {this.props.handlePageValid(this.state.isvalid)})
        this.setState(prevState => ({cardcolor: {...prevState.cardcolor, [i]: this.props.questionprops.touchcolor}}))
    }


   /// function to update chip allocation selections as well as determine if you are over/under or valid.  r is id, v is value
   changeAllocation(r, v){

    let newtotal = v
    for (let x of Object.keys(this.state.selectvalue)){
      if (x !== r){
        newtotal = newtotal + this.state.selectvalue[x]
      }
    }
    
    let newremain = this.props.questionprops.allocationamount - newtotal
    let validcheck = false

   if (newremain < 0){
     v = v + newremain
     newtotal = newtotal + newremain
     newremain = newremain - newremain
    }

   if(newtotal === this.props.questionprops.allocationamount){validcheck=true}

   if(this.props.questionprops.validationtype==="none"){validcheck=true}

    this.setState(prevState => ({
      selectvalue: {...prevState.selectvalue, [r]: v},
      cardcolor: {...prevState.cardcolor, [r]: this.props.surveyprops.questionpropdefaults.touchcolor},
      total: newtotal,
      remaining: newremain,
     isvalid: validcheck,
      }), () => {this.props.handlePageValid(this.state.isvalid)}
      )

}


    /// function to update choices, which also might mean unselecting other options to ensure there is only one
    /// best and one worst at any given time, and that a choice cant be both best and worst.  i is id, e is value
    changeMaxDiff(i, e){
        
        let newcolor = {}
        let newselections = {}
        let validcheck = false
        let validbest = false
        let validworst = false

        for (let b in this.state.selectvalue){
          if (this.state.selectvalue[b]===e){
            newselections[b] = null
            newcolor[b]= this.props.questionprops.noselectcolor
          } else {
            newselections[b] = this.state.selectvalue[b]
            newcolor[b] = this.state.cardcolor[b]
          }
        }
      
      newselections[i] = e
      if (e=="best"){
        newcolor[i] = this.props.questionprops.bestselectcolor}
      if (e==="worst"){
        newcolor[i] = this.props.questionprops.worstselectcolor}
      if (e==="selected"){
          newcolor[i] = this.props.questionprops.selectcolor}
      
      for(let c in newselections){
        if(newselections[c] === "selected"){validcheck = true}
        if(newselections[c] === "best"){validbest = true}
        if(newselections[c] === "worst"){validworst = true}
      }

      if (validbest && validworst){ validcheck = true}

      if(this.props.questionprops.validationtype==="none"){validcheck=true}


        this.setState({selectvalue:newselections, 
          cardcolor:newcolor,
          isvalid: validcheck
          }, () => {this.props.handlePageValid(this.state.isvalid)})
    }

    /// used to run through each of the of the radio or checkbox choices to see if they are valid (at least one true)
    validateRadioCheck(){
      let validcheck = true
      if(this.props.questionprops.validationtype==="all"){
          for(let i in this.state.selectvalue){
            const entries = Object.values(this.state.selectvalue[i])
            const entriesvalid = entries.includes(true)
            if (entriesvalid ===false){validcheck = false}
            }
      } 
      this.setState({isvalid: validcheck}, () => {this.props.handlePageValid(this.state.isvalid)})
      }
    
    /// used to change the bool values of the radio and checkbox items.  Radio/check are different in that they have
    /// another layer of selection - there is a dict of options, each of which can be true or false.  i is id, e is value, t is type
    toggleRadioCheck(i, e, t){

        if(t==='radio'){
            let newvals = this.state.selectvalue[i]
            for (let p in newvals)
                {
                newvals[p]=false
                }
            newvals[e]=true

            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [i]: newvals
                }
                }), () => {this.validateRadioCheck()}
                )
        } else {

            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [i]: {                     
                ...prevState.selectvalue[i], 
                [e]: !this.state.selectvalue[i][e]         
                }
                }
                }), () => {this.validateRadioCheck()}
                )
            }
    }


    renderAllocationCard(){

        return(
          <div style={{display:"flex", marginLeft:"30px"}}>
            <Card style={{backgroundColor:this.props.surveyprops.style.cardheadcolor, color:this.props.surveyprops.style.cardheadtextcolor, height:"40px", width:"60px", marginRight:"5px"}}>
              <p style={{fontSize:"2em", fontWeight:"600", marginLeft:"5px"}}>{this.state.total}</p>
              <p style={{fontSize:"0.5em"}}>total</p>
            </Card>
            {this.state.remaining !== 0 &&
            <Card style={{backgroundColor:this.props.surveyprops.style.cardheadtextcolor, color:this.props.surveyprops.style.cardheadcolor, height:"40px", width:"60px", marginRight:"5px"}}>
            <p style={{fontSize:"2em", fontWeight:"600", marginLeft:"5px"}}>{this.state.remaining}</p>
            <p style={{fontSize:"0.5em"}}>to go</p>
            </Card>
            }
             {this.state.remaining === 0 &&
             <div style={{display:"flex"}}>
            <Card style={{backgroundColor:this.props.surveyprops.style.cardheadcolor, color:this.props.surveyprops.style.cardheadtextcolor, height:"40px", width:"60px", marginRight:"5px"}}>
            <p style={{fontSize:"2em", fontWeight:"600", marginLeft:"5px"}}>{this.state.remaining}</p>
            <p style={{fontSize:"0.5em"}}>to go</p>
            </Card>
            <div>
               <span><i className="icon-check" style={{color:"#20a8d8", fontSize:"2em", marginLeft:"3px"}} ></i></span>
            </div>
            </div>
            }
    
          </div>
    
        )

    }

    /// calls the ChoiceCard component to create the card elements for each map of options
    getOptionCard(option){
      return(
        <ChoiceCard
          key={option['id']}
          mwidth={this.state.mwidth}
          surveyprops={this.props.surveyprops}
          option={option}
          selectvalue={this.state.selectvalue}
          validallocationamount={this.state.validallocationamount}
          remaining={this.state.remaining}
          value={this.state.selectvalue[option['id']]}
          cardcolor={this.state.cardcolor[option['id']]}
          questionprops={this.props.questionprops}
          questionId={this.props.questionId}
          onSelectionChange={this.changeSelection}
          onImageToggle={this.toggleLightbox}
          onAllocationChange={this.changeAllocation}
          onMaxDiffChange={this.changeMaxDiff}
          onRadioCheckToggle={this.toggleRadioCheck}
          />
      )
    }



   render(){


    return(

      <div>
      {this.state.showImage && (
        <Lightbox
          mainSrc={this.state.imageSrc}
          enableZoom={true}
          imageTitle={this.state.imageTitle}
          imageCaption={this.state.imageCaption}
          onCloseRequest={() => this.setState({ showImage: false })}
          />
      )}
      

      <div className = "survey-row-of-cards">

        {this.state.packagesonpage.map((option, key=option.id)=>this.getOptionCard(option))}
            
      </div>

        {this.props.questionprops.selectiontype === "allocation" &&
          this.renderAllocationCard()
        }
      </div>
    );
  }
}