import React, { Component } from "react";
import {ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText,} from 'reactstrap';

/// FeatureList - component that assembles individual features that are in a array for use in a card




function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }


export default class FeatureList extends Component {
    constructor(props) {
      super(props);
      this.state = {
          isLoading: true,
          features: this.props.features
      }
    }
     
    async componentWillMount() {
      if(this.props.randomize){
          this.setState({features: shuffle(this.props.features)})
      }
    }

    renderListItem(h, d){

      if(!this.props.isMobile){

        return(
        <ListGroupItem  style={{backgroundColor:this.props.surveyprops.style.featurebackgroundcolor, borderColor:this.props.surveyprops.style.featureoutlinecolor}}>
        <ListGroupItemHeading style={{fontSize:"0.8em", fontWeight:"200", marginTop:"3px", marginBottom:"0px"}}>{h}</ListGroupItemHeading>
        <ListGroupItemText style={{fontSize:"0.6em", fontWeight:"300",  marginTop:"0px", marginBottom:"3px"}}>{d}</ListGroupItemText>
        </ListGroupItem>
        )
      }

      if(this.props.isMobile){
        return(
          <ListGroupItem  style={{backgroundColor:this.props.surveyprops.style.featurebackgroundcolor, borderColor:this.props.surveyprops.style.featureoutlinecolor, paddingTop:"0px"}}>
          <ListGroupItemHeading style={{fontSize:"0.7em", fontWeight:"200", padding:"2px", marginTop:"0px", marginBottom:"0px"}}>{h}</ListGroupItemHeading>
          <ListGroupItemText style={{fontSize:"0.5em", fontWeight:"300", padding:"2px", marginTop:"0px", marginBottom:"3px"}}>{d}</ListGroupItemText>
          </ListGroupItem>
          )

      }

    }

    render(){

        return(
          <div>
          <hr style={{marginTop:"3px"}}/>
        <ListGroup className = "survey-feature-list">
        {this.state.features.map((item, key=item.id)=>this.renderListItem(item.header, item.detail))}
        {this.props.showprice &&
                <ListGroupItem style={{backgroundColor:this.props.surveyprops.style.pricebackgroundcolor, color:this.props.surveyprops.style.pricetextcolor}}>
                <ListGroupItemHeading style={{fontSize:"1.1em", fontWeight:"400",marginTop:"3px", marginBottom:"0px" }}>{Number(this.props.price).toFixed(2)}</ListGroupItemHeading>
                <ListGroupItemText style={{fontSize:"0.6em", fontWeight:"300", marginTop:"0px", marginBottom:"3px"}}>{this.props.pricemetric}</ListGroupItemText>
                </ListGroupItem>
        }
        </ListGroup>
        </div>
        )
    }
}