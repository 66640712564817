import React, { Component } from "react";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import { Card, CardBody,  CardHeader,  Col,  Row, } from 'reactstrap';


const optionheader1 = "Your Job Profile"

const options = [
  { value: 'PM', label: 'Product' },
  { value: 'UX', label: 'UX' },
  { value: 'MKT', label: 'Marketing' },
  { value: 'ENG', label: 'Engineering' },
];

const customStyles = (isValid) => ({
  control: (styles: any) => ({
    ...styles,
    ...(!isValid && { borderColor: 'red' }),
  }),
});

export default class SelectorWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoading: true,
        selectvalue: null,
        multivalue: [],
        selecteditem: null,
        multioption: null,
      };

    }
   
  async componentDidMount() {
    this.setState({ isLoading: false });
  }

  handleSelectChange = selectvalue => {
    this.setState({multivalue:[], multioption:null});
    this.setState(
      { selectvalue, selecteditem: selectvalue.value, multioption: this.getMultiOption(selectvalue.value)});
  };

  handleMultiChange = (newValue: any, actionMeta: any) => {
    this.setState(
      { multivalue: newValue });
  };

  getMultiOption(val){
      var foo=[{}];
      if (val ==="PM"){
        var foo = [
        { value: 'A', label: 'Jira' },
        { value: 'B', label: 'Aha!' },
        { value: 'C', label: 'Productboard' },
        ];
        }
    if (val ==="UX"){
        var foo = [
        { value: 'D', label: 'Figma' },
        { value: 'E', label: 'Sketch' },
        { value: 'F', label: 'Illustrator' },
        ];
        }
    if (val ==="MKT"){
        var foo = [
        { value: 'G', label: 'Hubspot' },
        { value: 'H', label: 'Salesforce' },
        { value: 'I', label: 'Marketo' },
        ];
        }
    if (val ==="ENG"){
        var foo = [
        { value: 'J', label: 'Jira' },
        { value: 'K', label: 'VisualStudio' },
        { value: 'L', label: 'GitHub' },
        ];
        }
    return foo


  }

  render(){
      
    const { selectedOption } = this.state;

    return (

       <div>
            <div className='survey-question-body'>

            <Row>

              <Col>

              <Card>
              
              <CardHeader className='survey-card-header'>{optionheader1}</CardHeader>
              
              <CardBody>
              <h4>Job Function</h4>           
              <Select
                    value={selectedOption}
                    onChange={this.handleSelectChange}
                    options={options}
                    styles={customStyles(true)}  /// false means there is an error
                />
                <p><small>Please select the role that best describes your current job</small></p>

                {this.state.multioption &&
                <div>
                    <h4>Software Used</h4>
                    <CreatableSelect
                        isMulti
                        onChange={this.handleMultiChange}
                        options={this.state.multioption}
                        styles={customStyles(true)}  /// false means there is an error
                    />
                    <p><small>Type to search, select or add your own item</small></p>
                </div>}

              </CardBody>

              </Card>
                           
              </Col>

            </Row>

            </div>
            </div>
    );
  }
}