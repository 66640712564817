import React, { Component } from "react";
import { Card, CardBody, CardFooter} from 'reactstrap';
import { API } from "aws-amplify";
import SurveyQuestion from '../survey/SurveyQuestion'
import {toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip'


/// SurveyPage - controls the forward/back flow of pages
/// requires page valid to toggle on the next button
/// requires saving page state on unmount from the detail question pages so when
/// they re-render the old choices can be displayed

function getDateTime(){
  let today = new Date();
  let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  let dateTime = date+' '+time;
  return (dateTime)
}

export default class SurveyPage extends Component {
  constructor(props) {
    super(props);
    this.saveSurvey = this.saveSurvey.bind(this)
    this.onValidPage = this.onValidPage.bind(this)
    this.state = {
        pagevalid: true,
        savedresponses: {},
        savedpages:{}
      };
    }
   
  async componentDidMount() {
  }

  onValidPage(v){
    this.setState({pagevalid:v})
  }

/// note that props.questions is a list, we are pulling toast from first in list
/// to-date we only have one question construct per page, so no issue...

  handlePageChange(e){
    if(this.props.questions[0].questionprops.questiontoast){
      toast.info(this.props.questions[0].questionprops.questiontoast)
    }


    /// need to get the last page to save...
    if(e==="COMPLETE"){
      
    }

    this.props.onPageChange(e)
  }

  saveResponse() {
    const saveresponse = {
      "surveyId": this.props.surveyId,
      "userId": this.props.userId, 
      "responseId": this.props.responseId,
      "surveystart": this.props.surveystart,
      "tracking": this.props.tracking,
      "lastupdate":getDateTime(),
      "pagevalid": this.state.pagevalid, 
      "savedpages": this.state.savedpages, 
      "savedresponses": this.state.savedresponses,
      "surveystatus": this.props.surveystatus
    }
   return API.put("responses", "/responses",{body:saveresponse});
    }

  saveSurvey(p, s, qprops){

/// passing qprops which has q type and other useful things... but not doing anything w it yet


    this.setState(prevState => ({
      savedresponses: {
      ...prevState.savedresponses,          
      [p]: s
      },
      savedpages: {
        ...prevState.savedpages,          
        [p]: true
      }
      }),() => {this.saveResponse()} )
  }

  renderQuestions(question){
      return(
    <SurveyQuestion 
      key={this.props.currentpage} 
      surveyprops={this.props.surveyprops}
      questiontype={question.questiontype}
      question={question}
      questionprops={question.questionprops}
      pageprops={this.props.pageprops}
      savedpages={this.state.savedpages[question.questionId]}
      savedresponses={this.state.savedresponses[question.questionId]}
      saveSurvey={this.saveSurvey}
      onPageValid={this.onValidPage}
      saveCards={this.props.saveCards}
      surveystatus={this.props.surveystatus}
      isMobile={this.props.isMobile}
      allresponses={this.state.savedresponses}
      />
      )}



  render(){
    return(
      
        <div>
          
          {this.props.pageprops.showtitle &&
            <div className="survey-page-headings" style={{color:this.props.surveyprops.style.pageheadtextcolor}}>
                <h1>{this.props.pageprops.pagetitle}</h1>
                <h4>{this.props.pageprops.pagesubtitle}</h4>
            </div>
            }
    
        <Card style={{backgroundColor:this.props.surveyprops.style.pagebodycolor}}>
        
            <CardBody className="survey-page-card-body">

            {this.props.questions.map((question, key=question.questionId)=>this.renderQuestions(question))}
                
            </CardBody>

            <CardFooter style={{backgroundColor:this.props.surveyprops.style.pagefootcolor}}>

            <div className="survey-page-navigation" >

            {!this.props.isFirst &&
            <button className="btn btn-pill btn-xl btn-secondary" onClick={() => this.handlePageChange("B")}><span class="fa fa-chevron-left"/>{this.props.pageprops.backbutton}</button>
            }

            {this.props.isFirst &&
            <button className="btn btn-pill btn-xl btn-secondary" disabled>{this.props.pageprops.backbutton}</button>
            }

            {!this.props.isLast && this.state.pagevalid &&
            <button className="btn btn-pill btn-xl btn-primary" style={{marginLeft:"auto"}} onClick={() => this.handlePageChange("F")}>{this.props.pageprops.nextbutton}<span class="fa fa-chevron-right"/></button>
            }

            {this.props.isLast &&
            <button className="btn btn-pill btn-xxl btn-primary" style={{marginLeft:"auto"}} onClick={() => this.handlePageChange("COMPLETE")}>{this.props.surveyprops.pagepropdefaults.completebutton}</button>
            }

           {!this.state.pagevalid &&
            <button className="btn btn-pill btn-xl btn-dark" disabled style={{marginLeft:"auto"}}>{this.props.pageprops.nextbutton}</button>
            }

            </div>

            </CardFooter>

        </Card>
        <div className="survey-page-footer">
            <div>
            {this.props.pageprops.showpagehint &&
            this.props.pageprops.showpagehint}
            </div> 
            {this.props.pageprops.showpagenums && 
              <div style={{marginLeft:"auto"}}>page {this.props.pageprops.thispage} of {this.props.pageprops.totalpages}</div>}  
        </div>
        </div>
    );
  }
}