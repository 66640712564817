import React, { Component } from "react";
import {  Col,  Row, } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './Tour.scss';
import ReactGA from 'react-ga';

import UXSurveyTour from "../tour/UXSurveyTour2"
import ListMaxDiffTour from "../tour/ListMaxDiffTour"
import UXAnnotationTour from "../tour/UXAnnotationTour"
import InputWidgetTour from "../tour/InputWidgetTour"
import SliderWidgetTour from "../tour/SliderWidgetTour";
import SelectorWidgetTour from "../tour/SelectorWidgetTour";
import ComboSurveyTour from "../tour/ComboSurveyTour";

const questname = "Capture the full range of feedback"
const questexplain = "We provide a range of options for collecting information from respondents - including input fields, multiselect, sliders and ranges."
const questexplain2 = "And, unlike many basic survey tools, we support custom variables, field validations and branching logic."


export default class Tour extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoading: true,
      };
    }
   
  async componentDidMount() {
    ReactGA.pageview('/tour')
    this.setState({ isLoading: false });
  }

  render(){
    return(

        <div className="wrapper">

        <div className="breadcrumb" style={{position:"relative", marginBottom:"0px"}}>
            <li><a href="/">Home</a></li>
            <li>Tour</li>
        </div>
   
        <div className="tour-hero">
          <div className="tour-hero-text">
          <h1>feature highlights</h1>
          <h3>a quick tour of pricekit</h3>
          </div>
        </div>

        <div className="tour static-white">
          <div className=" tour-body">
                <UXSurveyTour/>
            </div>

        </div>

        <div className="tour static-black">
        <div className=" tour-body">
                <ListMaxDiffTour/>
            </div>
        </div>

        <div className="tour static-white">
          <div className=" tour-body">
                <ComboSurveyTour/>
            </div>

        </div>

        <div className="tour static-black">
          <div className=" tour-body">
                <UXAnnotationTour/>
            </div>

        </div>

        <div className="tour static-white">
          <div className=" tour-body">
          <div className='tour-question-header'>
                <h2>{questname}</h2>
                <h3 style={{marginBottom:"10px"}}>{questexplain}</h3>
                <h4>{questexplain2}</h4>
            </div>
            <p style={{marginTop:"10px", marginBottom: "0px", marginLeft:"30px", color:"#20a8d8"}}>Try it:</p>
            <Row>
              <Col md-4>
                <InputWidgetTour/>
              </Col>
              <Col md-4>
                <SelectorWidgetTour/>
              </Col>
              <Col md-4>
                <SliderWidgetTour/>
              </Col>
              </Row>
            </div>

        </div> 

        <div className="tour static-black">
          <div className=" tour-body">
          <div className='tour-question-header'>
                <h2>Pricekit</h2>
                <h3>the fast, easy and accurate way </h3>
                <h3 style={{marginBottom:"20px"}}>to test concepts, create offers and optimize price</h3>
                <LinkContainer to="/contact">
            <button className="btn btn-pill btn-lg btn-outline-light">CONTACT US</button>
                </LinkContainer>
            </div>
            </div>

        </div> 

        <div className="breadcrumb" style={{marginBottom:"0px", borderTop: "1px solid #c8ced3"}}>
            <li><a href="/">Home</a></li>
            <li>Tour</li>
        </div>
        </div>

    );
  }
}