import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./views/Pages/Page404";
import FAQ from "./containers/FAQ";
import Contact from "./containers/Contact";
import Tour from "./containers/Tour";
import Privacy from "./containers/Privacy";
import Terms from "./containers/Terms";

import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/home" exact component={Home} props={childProps}/>

    <AppliedRoute path="/faq" exact component={FAQ} props={childProps}/>
    <AppliedRoute path="/contact" exact component={Contact} props={childProps}/>
    <AppliedRoute path="/tour" exact component={Tour} props={childProps} />
    <AppliedRoute path="/privacy" exact component={Privacy} props={childProps} />
    <AppliedRoute path="/terms" exact component={Terms} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;

