import React, { Component } from "react";
import { Card, CardBody, CardHeader, CardFooter, Col, Form, FormGroup, Input, CustomInput} from 'reactstrap';
import FeatureList from '../survey/FeatureList';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import GifPlayer from 'react-gif-player';
import ReactPlayer from 'react-player';
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Toggle from 'react-toggle'
import  'react-toggle/style.css'
import Image from '../components/Image'


/// ChoiceCard - assembles each card for SurveyCard
/// each card has the same select type function
/// there are various functions to handle input/events since the various
/// components used pass slightly different info
/// typically use onChange, but onBlur needed to handle numbers properly
/// this HAS STYLING which is needed to change the background color of selected and max-diff



/// styling for react-select
const customStyles = (isValid) => ({
    control: (styles: any) => ({
      ...styles,
      padding: 5,
      fontSize: "smaller",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });

   /// styling for react-select
   const customStyles2 = (isValid) => ({
    option: (provided, state) => ({
      ...provided,
      fontSize: '1em',
      borderBottom: '1px gray',
      backgroundColor: state.isSelected ? '#2f353a' : 'white',
      color: state.isSelected ? '#20a8d8' : '#2f353a',
      padding: 10,
      overflow: 'visible',
    }),
      control: (styles: any, state:any) => ({
        ...styles,
        color:"#2f353a",
        backgroundColor:"#f0f3f5",
        padding: 5,
        fontSize: "smaller",
        overflow: "visible",
        ...(!isValid && { borderColor: 'red' }),
      }),
    });


/// styling for range and slider
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;
const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <Tooltip
        prefixCls="rc-slider-tooltip"
        overlay={value}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </Tooltip>
    );
  };


/// for randomizing elements in a list, like options and features
function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}


export default class ChoiceCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isValid: false,
      };
    }
   
  async componentDidMount(){ 
  }

  /// callback to send option id (i) and the value of the event(e) back to parent component
  handleSelectionChange(i, e){
      this.props.onSelectionChange(i, e)
  }


  /// callback :  Used by DISCRETE and MAXDIFF
  handleMaxDiffChange(e){
    this.props.onMaxDiffChange(this.props.option.id, e)
}

  /// calls Selection Change callback:  Used by SELECT and MULTI AND SLIDER and RANGE
  handleSelectChange = (selectvalue:any, action: any) => {
      this.handleSelectionChange(this.props.option.id, selectvalue)
  };

  /// calls Selection Change
  handleToggleSelect(e){
    this.handleSelectionChange(this.props.option.id, e)
  }

  /// callback:  used by ALLOCATION
  handleAllocationChange = (event) => {
    const target = event.target;
    let value = Number(target.value);
    this.props.onAllocationChange(this.props.option.id, value)
  }

  /// calls Selection Change callback:  Used by TEXT-FIELD and TEXT-AREA
  handleFieldChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    this.handleSelectionChange(this.props.option.id, value)
  }

   /// calls Selection Change callback:  Used by TOGGLE
  handleToggleChange(){
    this.props.onSelectionChange(this.props.option.id, !this.props.selectvalue[this.props.option.id])
}


  getSliderId(id){
    if(this.state.sliderId !== id && id !== null){
    this.setState({sliderId: id})
    }
  }

  /// calls Selection Change callback:  Used by SELECT and MULTI --- not slider range here as it doesnt pass name via action
  handleSliderChange = (selectvalue:any, action: any) => {
    this.handleSelectionChange(this.state.sliderId, selectvalue)
  };

 
    /// callback:  used by RADIO and CHECKBOX
    handleRadioCheckChange = (event) => {
      const target = event.target;
      if(target != null){
        let type = target.type
        let value = target.value
        const name = target.name;
        this.props.onRadioCheckToggle(name, value, type)
      }
    }


    /// used to generate the radio and checkbox items
    getSelectItems(item, type, qid){

      let itemkey = qid + item['value']

      if(type==="checkbox"){
        return(
        <div style={{marginRight:"15px", fontSize:"1em", fontWeight:"200", color:this.props.surveyprops.style.cardcardtextcolor}}>
        <CustomInput 
          type="checkbox" 
          id={itemkey} 
          defaultChecked={this.props.selectvalue[qid][item['value']]} 
          value={item['value']}
          label={item['label']} name={qid} key={itemkey} type={"checkbox"}
        ></CustomInput>
        </div>
        )}

        if(type==="radio"){
          return(
          <div style={{marginRight:"15px", fontSize:"1em", fontWeight:"200", color:this.props.surveyprops.style.cardcardtextcolor}}>
          <CustomInput 
            type="radio" 
            id={itemkey} 
            defaultChecked={this.props.selectvalue[qid][item['value']]}  
            label={item['label']} value={item['value']}
            name={qid} key={itemkey} type={"radio"}
          ></CustomInput>
          </div>
        )}
    }



  /// called from render - this determines which selection component to use in the card footer
  renderSelectType(){

    if (this.props.questionprops.selectiontype === "select"){
         return(
            <Select
            readOnly = { true}
            isSearchable={ false }
            inputProps={{readOnly:true, readOnly: 'true'}}  /// this is used to hide the keyboard on mobile devices
            defaultValue={this.props.selectvalue[this.props.option.id]}
            onChange={this.handleSelectChange}
            options={this.props.questionprops.selectoptions}
            styles={customStyles2(true)}  /// false means there is an error and will error style
          />
        )
        }
    if (this.props.questionprops.selectiontype === "selectcreatable"){
        return(
            <CreatableSelect
            defaultValue={this.props.selectvalue[this.props.option.id]}
            onChange={this.handleSelectChange}
            options={this.props.questionprops.selectoptions}
            styles={customStyles2(true)}  
            />
        )
        }
    if (this.props.questionprops.selectiontype === "multiselect"){
        return(
            <Select
            readOnly = { true}
            isSearchable={ false }
            inputProps={{readOnly:true}}  /// this is used to hide the keyboard on mobile devices
            defaultValue={this.props.selectvalue[this.props.option.id]}
            isMulti
            onChange={this.handleSelectChange}
            options={this.props.questionprops.selectoptions}
            styles={customStyles2(true)}  
            />
        )
        }   
    if (this.props.questionprops.selectiontype === "multiselectcreatable"){
        return(
            <CreatableSelect
            isMulti
            defaultValue={this.props.selectvalue[this.props.option.id]}
            onChange={this.handleSelectChange}
            options={this.props.questionprops.selectoptions}
            styles={customStyles2(true)}  
            />
        )
        }
    if (this.props.questionprops.selectiontype === "discrete"){
        return(
            <button className="btn btn-pill btn-xl btn-outline-dark" style={{color:this.props.surveyprops.style.cardfootcolor, background:this.props.surveyprops.style.cardfoottextcolor, borderColor:"#20a8d8"}} onClick={() => this.handleMaxDiffChange("selected")}>{this.props.questionprops.buttonname}</button>
        )
        }
    if (this.props.questionprops.selectiontype === "maxdiff"){
        return(
        <div style={{display: "flex"}}>
        <button className="btn btn-pill btn-lg btn-outline-success" style={{color:this.props.surveyprops.style.cardfootcolor, background:this.props.surveyprops.style.cardfoottextcolor, borderColor:"#4dbd74"}} onClick={() => this.handleMaxDiffChange("best")}>{this.props.questionprops.bestbuttonname}</button>
        <button className="btn btn-pill btn-lg btn-outline-warning" style={{color:this.props.surveyprops.style.cardfootcolor, background:this.props.surveyprops.style.cardfoottextcolor, borderColor:"#f86c6b", marginLeft:"auto"}} onClick={() => this.handleMaxDiffChange("worst")}>{this.props.questionprops.worstbuttonname}</button>
        </div>
        )
        }


      if (this.props.questionprops.selectiontype === "allocation"){
        return(
          <div style={{display:"flex", marginTop:"15px", marginBottom:"45px"}}>
          <Slider 
          marks={{0:"0", [this.props.remaining + this.props.selectvalue[this.props.option.id]]: <div style={{display:"flex", flexDirection:"column", fontSize:"0.6em", margtinTop:"0px", marginBottom:"0px"}}><span><i className="icon-arrow-up" style={{color:"#f86c6b", marginTop:"0px"}} ></i></span><p>{`+ ${this.props.remaining}`}</p></div>}}
          min={0}
          max={this.props.questionprops.allocationamount}
          step={this.props.questionprops.sliderstep}
          defaultValue={this.props.selectvalue[this.props.option.id]}
          onAfterChange={(e)=>this.props.onAllocationChange(this.props.option.id, e)}
          tipFormatter={value => `${value}`}
          handle={handle}
          dotStyle={{borderColor: '#f86c6b'}}
          activeDotStyle={{borderColor: '#c8ced3'}}
          />
          <div style={{width:"30px", marginLeft:"20px", fontSize:"1.5em", fontWeight:"600"}}>
          {this.props.selectvalue[this.props.option.id]}
          </div>
          </div>
        )
        
      }




    if (this.props.questionprops.selectiontype === "toggle"){
      return(
          <Toggle
          id={this.props.option.id}
          value={this.props.option.togglevalue}
          checked= {this.props.selectvalue[this.props.option.id]}
          icons={{unchecked: null}}
          className = {this.props.option.togglestyle}
          onChange = {() => this.handleToggleChange()}
      />
      )
    }
    if (this.props.questionprops.selectiontype === "text-field"){
      return(

        <Input 
        style={{fontSize:"1em", padding:"5px"}}
        name={this.props.option.id}
        type={this.props.option.texttype} 
        value={this.props.selectvalue[this.props.option.id]}
        onChange={this.handleFieldChange}
        />
      )
    }


    if (this.props.questionprops.selectiontype === "text-area"){
    return(
      <Input 
      style={{fontSize:"1em", padding:"5px"}}
      name={this.props.option.id}
      type={"textarea"} 
      value={this.props.selectvalue[this.props.option.id]}
      onChange={this.handleFieldChange}
      />
    )
    }


    if (this.props.questionprops.selectiontype === "checkbox"){
      return(
        <Form>
        <FormGroup inline name={this.props.option.id} onBlur={this.handleRadioCheckChange} >
          {this.props.questionprops.selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "checkbox", this.props.option.id))}
        </FormGroup>
  </Form>

      )
  }

    if (this.props.questionprops.selectiontype === "radio"){
      return(
        <Form>
        <FormGroup inline name={this.props.option.id} onBlur={this.handleRadioCheckChange} >
          {this.props.questionprops.selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "radio",  this.props.option.id))}
        </FormGroup>
  </Form>

      )
    }
    if (this.props.questionprops.selectiontype === "slider"){
      return(
        <div style={{marginTop:"15px", marginLeft:"15px", marginRight:"15px", marginBottom:"30px"}}>
        <Slider 
        marks={this.props.questionprops.slidermarks}
        min={this.props.questionprops.slidermin}
        max={this.props.questionprops.slidermax}
        step={this.props.questionprops.sliderstep}
        defaultValue={this.props.selectvalue[this.props.option.id]}
        onChange={this.getSliderId(this.props.option.id)}
        onAfterChange={this.handleSliderChange}
        dotStyle={{borderColor: '#c8ced3'}}
        activeDotStyle={{borderColor: '#c8ced3'}}
        />
        </div>
      )
    }
    if (this.props.questionprops.selectiontype === "range"){
      return(
        <div style={{marginTop:"15px", marginLeft:"15px", marginRight:"15px", marginBottom:"30px"}}>
          <Range 
          marks={this.props.questionprops.slidermarks}
          min={this.props.questionprops.slidermin}
          max={this.props.questionprops.slidermax}
          step={this.props.questionprops.sliderstep} 
          defaultValue={this.props.selectvalue[this.props.option.id]}
          tipFormatter={value => `${value}`}
          onChange={this.getSliderId(this.props.option.id)}
          onAfterChange={this.handleSliderChange}
          />
        </div>
      )
    }


  }

  render(){

    return(  


        <Card className="survey-choice-card" style={{width:this.props.mwidth}}>
            
            {this.props.option.optionheader &&
          <CardHeader style={{backgroundColor:this.props.surveyprops.style.cardheadcolor, color:this.props.surveyprops.style.cardheadtextcolor}}>{this.props.option.optionheader}</CardHeader>
            }
          
          <CardBody style={{backgroundColor: this.props.cardcolor}}>

            {this.props.option.showimage &&  !this.props.isMobile &&
              <div>
              <div style={{height:"180px"}} tag="a"  onClick={() => this.props.onImageToggle(this.props.option['img'], this.props.option['imageTitle'], this.props.option['imageCaption'])} >
              <Image src={this.props.option.img} width={'100%'} height={180} mode='fit' />
              </div>
              </div>
            }

            {this.props.option.showimage &&  this.props.isMobile && this.props.showfeatures &&
              <div style={{height:"75px"}} tag="a"  onClick={() => this.props.onImageToggle(this.props.option['img'], this.props.option['imageTitle'], this.props.option['imageCaption'])} >
              <Image src={this.props.option.img} width={'100%'} height={75} mode='fit' />
              </div>
            }

            {this.props.option.showimage &&  this.props.isMobile && !this.props.showfeatures &&
              <div tag="a"  onClick={() => this.props.onImageToggle(this.props.option['img'], this.props.option['imageTitle'], this.props.option['imageCaption'])} >
              <Image src={this.props.option.img} width={'100%'} height={150} mode='fit' />
              </div>
            }

            {this.props.option.showgif &&
                <div style={{cursor: "pointer"}}>
                <GifPlayer
                    gif={this.props.option.gif}
                    width="100%"
                    />
                <p><small>Click to play</small></p>
                </div>
                
            }

            {this.props.option.showvideo &&
                <div>
                <ReactPlayer
                    url={this.props.option.video}
                    controls='true'
                    muted='true'
                    width='100%'
                    />
                </div>
            }

            {this.props.option.showfeatures &&  
                <FeatureList
                key={this.props.option.id}
                surveyprops={this.props.surveyprops}
                features={this.props.option.features}
                randomize={this.props.option.randomize}
                showprice={this.props.option.showprice}
                price={this.props.option.price}
                pricemetric={this.props.option.pricemetric}
                isMobile={this.props.isMobile}
            />}
        
          </CardBody>

          <CardFooter className="survey-choice-card-footer" style={{backgroundColor:this.props.surveyprops.style.cardfootcolor, color: this.props.surveyprops.style.cardfoottextcolor}}>

          {this.renderSelectType()}

          <div style={{ marginTop:"15px"}}>
            <p><small>{this.props.questionprops.helpmessage}</small></p>
          </div>

          </CardFooter>

        </Card>           

        
    );
  }
}