import React, { Component } from "react";
import { Button, Card, CardBody, CardFooter} from 'reactstrap';


export default class SurveyNotLive extends Component {
  constructor(props) {
    super(props);

    this.state = {
       
      };
    }
   
  async componentDidMount() {
  }

showMessage(){
    if(this.props.surveystatus==="Not Found"){
        return(
            <h1>sorry... but we didn't find a survey for this address</h1>
        )
    }

    if(this.props.surveystatus==="PENDING"){
        return(
            <h1>sorry... the survey at this address isn't active yet</h1>
        )
    }

    if(this.props.surveystatus==="TEST PENDING"){
        return(
            <h1>sorry... the test survey at this address isn't active yet</h1>
        )
    }

    if(this.props.surveystatus==="COMPLETE"){
        return(
            <h1>thank you for your interest, but this survey is now closed</h1>
        )
    }

    if(this.props.surveystatus==="DRAFT"){
        return(
            <h1>your survey isn't yet published...</h1>
        )
    }

    if(this.props.surveystatus==="RESPONDENT COMPLETE"){
        return(
            <h1>thank you for participating in this survey...</h1>
        )
    }
}



  render(){
    return(
        <div style={{
            height:"100vh",
            width:"100vw",
            marginTop:"-30px",
            overflow:"hidden",
            backgroundColor:"#2f353a",
            backgroundImage: "url(" + "https://pricekit-survey.s3.amazonaws.com/public/Default/bee-transparent.png" + ")",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        
        }}>

<div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"80vh"}}>
    <div style={{maxWidth:"50%", fontSize:"2em", color:"#f0f3f5", alignItems:"center"}}>
        {this.showMessage()}
        {this.props.surveystatus !=="DRAFT" &&
        <div style={{marginTop:"60px"}}>
            <a className="btn btn-lg btn-primary" href="https://pricekit.io">OK</a>
        </div>
        }
        {this.props.surveystatus ==="DRAFT" &&
        <div style={{marginTop:"60px"}}>
            <a className="btn btn-lg btn-primary" href="https://app.pricekit.io">LOG IN</a>
        </div>
        }

    </div>
</div>



        </div>
    );
  }
}