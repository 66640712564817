import React, { Component } from "react";
import { Card, CardBody,  CardHeader,  Col,  Row, } from 'reactstrap';

import Toggle from 'react-toggle'
import  'react-toggle/style.css';


const questname = "Understanding what features matter most"
const questexplain = "Getting insight into what drives value for customers and prospects is at the heart of product management and UX design."
const questexplain2 = "Pricekit allows you to rank items using max-diff analysis.  Respondents are presented with a set of options and they choose the most and least important to them."
const questexplain3 = "You likely have many existing and planned features that we can spread out across a sequence of questions over multiple respondents to develop particularly detailed insights."

const optionheader1 = "BeeHive 2000 Feature List"
const col1_header = "Most Important"
const description_header = "Description"
const col2_header = "Least Important"

const option1_listdetail1 = "Single Sign-On using enterprise credentials"
const option1_listdetail2 = "Map-view of hive hot-spot activity"
const option1_listdetail3 = "Honey predictor analytic model and reports"
const option1_listdetail4 = "Integration with Salesforce"
const option1_listdetail5 = "Queen maintenance and ticketing workflow"
const option1_listdetail6 = "BeeHive 2000 Alexa voice support"
const option1_listdetail7 = "Heat and humidity alerts by text, email and drone"


export default class ListMaxDiffTour extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoading: true,
        choice1best: false,
        choice2best: false,
        choice3best: false,
        choice4best: false,
        choice5best: false,
        choice1worst: false,
        choice2worst: false,
        choice3worst: false,
        choice4worst: false,
        choice5worst: false,
      };
    }
   
  async componentDidMount() {
    this.setState({ isLoading: false });
  }

  toggleBest(s){
    
    if(s===1){
          this.setState({
              choice1best: !this.state.choice1best,
              choice1worst: false,
             });
            if (this.state.choice2best===true){
              this.setState({
                choice2best: false,
              })}
            if (this.state.choice3best===true){
              this.setState({
                choice3best: false,
              })}           
            if (this.state.choice4best===true){
              this.setState({
                choice4best: false,
              })}
            if (this.state.choice5best===true){
              this.setState({
                choice5best: false,
              })}
            if (this.state.choice6best===true){
              this.setState({
                choice6best: false,
              })}
            if (this.state.choice7best===true){
              this.setState({
                choice7best: false,
              })}
          }
      
    if(s===2){
          this.setState({
            choice2best: !this.state.choice2best,
            choice2worst: false,
          });
          if (this.state.choice1best===true){
            this.setState({
              choice1best: false,
            })}
          if (this.state.choice3best===true){
            this.setState({
              choice3best: false,
            })}           
          if (this.state.choice4best===true){
            this.setState({
              choice4best: false,
            })}
          if (this.state.choice5best===true){
            this.setState({
              choice5best: false,
            })}
          if (this.state.choice6best===true){
            this.setState({
              choice6best: false,
            })}
          if (this.state.choice7best===true){
            this.setState({
              choice7best: false,
            })}
      }
        
    if(s===3){
          this.setState({
            choice3best: true,
            choice3worst: false,
          });
          if (this.state.choice1best===true){
            this.setState({
              choice1best: false,
            })}
          if (this.state.choice2best===true){
            this.setState({
              choice2best: false,
            })}           
          if (this.state.choice4best===true){
            this.setState({
              choice4best: false,
            })}
          if (this.state.choice5best===true){
            this.setState({
              choice5best: false,
            })}
          if (this.state.choice6best===true){
            this.setState({
              choice6best: false,
            })}
          if (this.state.choice7best===true){
            this.setState({
              choice7best: false,
            })}
        }

    if(s===4){
          this.setState({
            choice4best: true,
            choice4worst: false,
          });
          if (this.state.choice1best===true){
            this.setState({
              choice1best: false,
            })}
          if (this.state.choice2best===true){
            this.setState({
              choice2best: false,
            })}           
          if (this.state.choice3best===true){
            this.setState({
              choice3best: false,
            })}
          if (this.state.choice5best===true){
            this.setState({
              choice5best: false,
            })}
          if (this.state.choice6best===true){
            this.setState({
              choice6best: false,
            })}
          if (this.state.choice7best===true){
            this.setState({
              choice7best: false,
            })}
      }

      if(s===5){
        this.setState({
          choice5best: true,
          choice5worst: false,
        });
        if (this.state.choice1best===true){
          this.setState({
            choice1best: false,
          })}
        if (this.state.choice2best===true){
          this.setState({
            choice2best: false,
          })}           
        if (this.state.choice3best===true){
          this.setState({
            choice3best: false,
          })}
        if (this.state.choice4best===true){
          this.setState({
            choice4best: false,
          })}
        if (this.state.choice6best===true){
          this.setState({
            choice6best: false,
          })}
        if (this.state.choice7best===true){
          this.setState({
            choice7best: false,
          })}
    }

    
    if(s===6){
      this.setState({
        choice6best: true,
        choice6worst: false,
      });
      if (this.state.choice1best===true){
        this.setState({
          choice1best: false,
        })}
      if (this.state.choice2best===true){
        this.setState({
          choice2best: false,
        })}           
      if (this.state.choice3best===true){
        this.setState({
          choice3best: false,
        })}
      if (this.state.choice4best===true){
        this.setState({
          choice4best: false,
        })}
      if (this.state.choice5best===true){
        this.setState({
          choice5best: false,
        })}
      if (this.state.choice7best===true){
        this.setState({
          choice7best: false,
        })}
  }

  
  if(s===7){
    this.setState({
      choice7best: true,
      choice7worst: false,
    });
    if (this.state.choice1best===true){
      this.setState({
        choice1best: false,
      })}
    if (this.state.choice2best===true){
      this.setState({
        choice2best: false,
      })}           
    if (this.state.choice3best===true){
      this.setState({
        choice3best: false,
      })}
    if (this.state.choice4best===true){
      this.setState({
        choice4best: false,
      })}
    if (this.state.choice5best===true){
      this.setState({
        choice5best: false,
      })}
    if (this.state.choice6best===true){
      this.setState({
        choice6best: false,
      })}
}

      return
  }

  toggleWorst(s){
    
    if(s===1){
          this.setState({
            choice1worst: !this.state.choice1worst,
            choice1best: false,
          });
          if (this.state.choice2worst===true){
            this.setState({
              choice2worst: false,
            })}
          if (this.state.choice3worst===true){
            this.setState({
              choice3worst: false,
            })}           
          if (this.state.choice4worst===true){
            this.setState({
              choice4worst: false,
            })}
          if (this.state.choice5worst===true){
            this.setState({
              choice5worst: false,
            })}
          if (this.state.choice6worst===true){
            this.setState({
              choice6worst: false,
            })}
          if (this.state.choice7worst===true){
            this.setState({
              choice7worst: false,
            })}
        }
      
    if(s===2){
          this.setState({
            choice2worst: true,
            choice2best: false,
          });
          if (this.state.choice1worst===true){
            this.setState({
              choice1worst: false,
            })}
          if (this.state.choice3worst===true){
            this.setState({
              choice3worst: false,
            })}           
          if (this.state.choice4worst===true){
            this.setState({
              choice4worst: false,
            })}
          if (this.state.choice5worst===true){
            this.setState({
              choice5worst: false,
            })}
          if (this.state.choice6worst===true){
            this.setState({
              choice6worst: false,
            })}
          if (this.state.choice7worst===true){
            this.setState({
              choice7worst: false,
            })}
        }
        
    if(s===3){
          this.setState({
            choice3worst: true,
            choice3best: false,
          });
          if (this.state.choice1worst===true){
            this.setState({
              choice1worst: false,
            })}
          if (this.state.choice2worst===true){
            this.setState({
              choice2worst: false,
            })}           
          if (this.state.choice4worst===true){
            this.setState({
              choice4worst: false,
            })}
          if (this.state.choice5worst===true){
            this.setState({
              choice5worst: false,
            })}
          if (this.state.choice6worst===true){
            this.setState({
              choice6worst: false,
            })}
          if (this.state.choice7worst===true){
            this.setState({
              choice7worst: false,
            })}
        }

    if(s===4){
          this.setState({
            choice4worst: true,
            choice4best: false,
          });
          if (this.state.choice1worst===true){
            this.setState({
              choice1worst: false,
            })}
          if (this.state.choice2worst===true){
            this.setState({
              choice2worst: false,
            })}           
          if (this.state.choice3worst===true){
            this.setState({
              choice3worst: false,
            })}
          if (this.state.choice5worst===true){
            this.setState({
              choice5worst: false,
            })}
          if (this.state.choice6worst===true){
            this.setState({
              choice6worst: false,
            })}
          if (this.state.choice7worst===true){
            this.setState({
              choice7worst: false,
            })}
        }

        if(s===5){
          this.setState({
            choice5worst: true,
            choice5best: false,
          });
          if (this.state.choice1worst===true){
            this.setState({
              choice1worst: false,
            })}
          if (this.state.choice2worst===true){
            this.setState({
              choice2worst: false,
            })}           
          if (this.state.choice3worst===true){
            this.setState({
              choice3worst: false,
            })}
          if (this.state.choice4worst===true){
            this.setState({
              choice4worst: false,
            })}
          if (this.state.choice6worst===true){
            this.setState({
              choice6worst: false,
            })}
          if (this.state.choice7worst===true){
            this.setState({
              choice7worst: false,
            })}
        }

        if(s===6){
          this.setState({
            choice6worst: true,
            choice6best: false,
          });
          if (this.state.choice1worst===true){
            this.setState({
              choice1worst: false,
            })}
          if (this.state.choice2worst===true){
            this.setState({
              choice2worst: false,
            })}           
          if (this.state.choice3worst===true){
            this.setState({
              choice3worst: false,
            })}
          if (this.state.choice4worst===true){
            this.setState({
              choice4worst: false,
            })}
          if (this.state.choice5worst===true){
            this.setState({
              choice5worst: false,
            })}
          if (this.state.choice7worst===true){
            this.setState({
              choice7worst: false,
            })}
        }

        if(s===7){
          this.setState({
            choice7worst: true,
            choice7best: false,
          });
          if (this.state.choice1worst===true){
            this.setState({
              choice1worst: false,
            })}
          if (this.state.choice2worst===true){
            this.setState({
              choice2worst: false,
            })}           
          if (this.state.choice3worst===true){
            this.setState({
              choice3worst: false,
            })}
          if (this.state.choice4worst===true){
            this.setState({
              choice4worst: false,
            })}
          if (this.state.choice5worst===true){
            this.setState({
              choice5worst: false,
            })}
          if (this.state.choice6worst===true){
            this.setState({
              choice6worst: false,
            })}
        }

      return
  }

  render(){

    return(  

      <div>

            <div className='tour-question-header'>
                <h2>{questname}</h2>
                <h3 style={{marginBottom:"10px"}}>{questexplain}</h3>
                <h4>{questexplain2}</h4>
                <h4>{questexplain3}</h4>
            </div>

      <div className='survey-question-body'>
      <p style={{marginTop:"10px", color:"#20a8d8"}}>Try it:</p>

            <Row>
              <Col md="6">
              <Card >
              <CardHeader className='survey-card-header' style={{color:"black"}}>{optionheader1}</CardHeader>
                <CardBody style={{fontSize:"150%"}}>
                <table class="table">
                <thead class="thead-dark">
                    <tr>
                    <th scope="col" style={{width: "20%"}}>{col1_header}</th>
                    <th scope="col" style={{width: "60%"}} >{description_header}</th>
                    <th scope="col" style={{width: "20%"}} >{col2_header}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>
                    <Toggle
                      id='Best 1'
                      checked= {this.state.choice1best}
                      defaultChecked = {this.state.choice1best}
                      className = 'custom-primary'
                      icons={{unchecked: null}}
                      onChange = {() => this.toggleBest(1)}
                    />
                    </td>
                    <td>{option1_listdetail1}</td>
                    <td>
                    <Toggle
                      id='Worst 1'
                      checked= {this.state.choice1worst}
                      defaultChecked = {this.state.choice1worst}
                      className = 'custom-danger'
                      icons={{unchecked: null}}
                      onChange = {() => this.toggleWorst(1)}
                    />
                    </td>
                    </tr>

                    <tr>
                    <td>
                    <Toggle
                      id='Best 2'
                      checked= {this.state.choice2best}
                      defaultChecked = {this.state.choice2best}
                      className = 'custom-primary'
                      icons={{unchecked: null}}
                      onChange = {() => this.toggleBest(2)}
                    />
                    </td>
                    <td>{option1_listdetail2}</td>
                    <td>
                    <Toggle
                      id='Worst 2'
                      checked= {this.state.choice2worst}
                      defaultChecked = {this.state.choice2worst}
                      className = 'custom-danger'
                      icons={{unchecked: null}}
                      onChange = {() => this.toggleWorst(2)}
                    />
                    </td>
                    </tr>

                    <tr>
                    <td>
                    <Toggle
                      id='Best 3'
                      checked= {this.state.choice3best}
                      defaultChecked = {this.state.choice3best}
                      className = 'custom-primary'
                      icons={{unchecked: null}}
                      onChange = {() => this.toggleBest(3)}
                    />
                    </td>
                    <td>{option1_listdetail3}</td>
                    <td>
                    <Toggle
                      id='Worst 3'
                      checked= {this.state.choice3worst}
                      defaultChecked = {this.state.choice3worst}
                      className = 'custom-danger'
                      icons={{unchecked: null}}
                      onChange = {() => this.toggleWorst(3)}
                    />
                    </td>
                    </tr>

                    <tr>
                    <td>
                    <Toggle
                      id='Best 4'
                      checked= {this.state.choice4best}
                      defaultChecked = {this.state.choice4best}
                      className = 'custom-primary'
                      icons={{unchecked: null}}
                      onChange = {() => this.toggleBest(4)}
                    />
                    </td>
                    <td>{option1_listdetail4}</td>
                    <td>
                    <Toggle
                      id='Worst 4'
                      checked= {this.state.choice4worst}
                      defaultChecked = {this.state.choice4worst}
                      className = 'custom-danger'
                      icons={{unchecked: null}}
                      onChange = {() => this.toggleWorst(4)}
                    />
                    </td>
                    </tr>

                    <tr>
                    <td>
                    <Toggle
                      id='Best 5'
                      checked= {this.state.choice5best}
                      defaultChecked = {this.state.choice5best}
                      className = 'custom-primary'
                      icons={{unchecked: null}}
                      onChange = {() => this.toggleBest(5)}
                    />
                    </td>
                    <td>{option1_listdetail5}</td>
                    <td>
                    <Toggle
                      id='Worst 5'
                      checked= {this.state.choice5worst}
                      defaultChecked = {this.state.choice5worst}
                      className = 'custom-danger'
                      icons={{unchecked: null}}
                      onChange = {() => this.toggleWorst(5)}
                    />
                    </td>
                    </tr>

                    <tr>
                    <td>
                    <Toggle
                      id='Best 6'
                      checked= {this.state.choice6best}
                      defaultChecked = {this.state.choice6best}
                      className = 'custom-primary'
                      icons={{unchecked: null}}
                      onChange = {() => this.toggleBest(6)}
                    />
                    </td>
                    <td>{option1_listdetail6}</td>
                    <td>
                    <Toggle
                      id='Worst 6'
                      checked= {this.state.choice6worst}
                      defaultChecked = {this.state.choice6worst}
                      className = 'custom-danger'
                      icons={{unchecked: null}}
                      onChange = {() => this.toggleWorst(6)}
                    />
                    </td>
                    </tr>
                    <tr>
                    <td>
                    <Toggle
                      id='Best 7'
                      checked= {this.state.choice7best}
                      defaultChecked = {this.state.choice7best}
                      className = 'custom-primary'
                      icons={{unchecked: null}}
                      onChange = {() => this.toggleBest(7)}
                    />
                    </td>
                    <td>{option1_listdetail7}</td>
                    <td>
                    <Toggle
                      id='Worst 7'
                      checked= {this.state.choice7worst}
                      defaultChecked = {this.state.choice7worst}
                      className = 'custom-danger'
                      icons={{unchecked: null}}
                      onChange = {() => this.toggleWorst(7)}
                    />
                    </td>
                    </tr>

                </tbody>
                </table>
                </CardBody>
                </Card>
              </Col>

            </Row>
        </div>

        </div>

    );
  }
}