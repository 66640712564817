import React, { Component } from "react";
import { Card, CardBody,  CardHeader, CardFooter, Form, FormGroup, CustomInput, Input } from 'reactstrap';
import FeatureList from '../survey/FeatureList';
import Annotation from 'react-image-annotation';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Toggle from 'react-toggle'
import  'react-toggle/style.css'


/// styling for react-select
const customStyles = (isValid) => ({
  control: (styles: any) => ({
    ...styles,
    padding: 5,
    fontSize: "smaller",
    ...(!isValid && { borderColor: 'red' }),
  }),
});

    /// styling for react-select
    const customStyles2 = (isValid) => ({
      option: (provided, state) => ({
        ...provided,
        fontSize: '0.9em',
        borderBottom: '1px gray',
        backgroundColor: state.isSelected ? '#2f353a' : 'white',
        color: state.isSelected ? '#20a8d8' : '#2f353a',
        padding: 10,
        overflow: 'visible',
      }),
        control: (styles: any, state:any) => ({
          ...styles,
          color:"#2f353a",
          backgroundColor:"#f0f3f5",
          padding: 5,
          fontSize: "smaller",
          overflow: "visible",
          ...(!isValid && { borderColor: 'red' }),
        }),
      });

/// styling for range and slider
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

export default class UXAnnotation extends Component {
  constructor(props) {
    super(props);
    let initialannotations = []
    let initialselections = {}
    let initializefalse = ['checkbox', 'radio']
    initialannotations = this.props.options[0]['annotations']
    let fcardwidth = "25%"
    if(this.props.isMobile){fcardwidth="100%"}
    if (typeof this.props.savedpages != "undefined") {
      if(this.props.savedpages===true){
          initialannotations =  this.props.savedresponses['annotations']
          initialselections = this.props.savedresponses['selectvalue']
      }
    }  else {
      for (let i of this.props.options){
          let radiocheck = {}        
          initialselections[i['id']] = null
          if(this.props.questionprops.selectiontype==='toggle'){initialselections[i['id']] = false}
          if(this.props.questionprops.selectiontype==='slider'){initialselections[i['id']] = i['sliderdefault']}
          if(this.props.questionprops.selectiontype==='range'){initialselections[i['id']] = i['rangedefault']}
          if(initializefalse.includes(this.props.questionprops.selectiontype))
              {
              for (let s of i['selectoptions']){
                  radiocheck[s['value']]=false
              }
              initialselections[i['id']]=radiocheck
              }
      }
  }

    this.state = {
      options: this.props.options,
      annotations: initialannotations,
      annotation: {},
      selectvalue: initialselections,
      allocation:null,
      fcardwidth: fcardwidth,
      pagetype:"DesignCard",
      selectiontype:this.props.questionprops.selectiontype

    }
  }

  async componentDidMount(){ 

  }

  componentWillUnmount(){
    this.props.saveSurvey(this.props.questionId, this.state, this.props.questionprops)
}


  onChange = (annotation) => {
    this.setState({ annotation })
  }

  onSubmit = (annotation) => {
    const { geometry, data } = annotation
    this.setState({
      annotation: {},
      annotations: this.state.annotations.concat({
        geometry,
        data: {
          ...data,
          id: Math.random()
        }
      })
    })
  }

  changeSelection(i, e){
    this.setState(prevState => ({selectvalue: {...prevState.selectvalue, [i]: e}}))
}

  /// callback to handle the select or multiselect change, only event used at this point for anything
  handleSelectChange = (selectvalue:any, action: any) => {
      this.changeSelection(this.props.options[0].id, selectvalue)
  };

  handleToggleSelect(e){
    this.changeSelection(this.props.options[0].id, e)
  }

  handleFieldChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    this.changeSelection(this.props.options[0].id, value)
  }

  handleToggleChange(){
    this.changeSelection(this.props.options[0].id, !this.state.selectvalue[this.props.options[0].id])
  }

  getWidth(a){
    if (vw<1000){

      return "100%"

    } else {return "70%"}
  
  }

  toggleRadioCheck(i, e, t){

    if(t==='radio'){
        let newvals = this.state.selectvalue[i]
        for (let p in newvals)
            {
            newvals[p]=false
            }
        newvals[e]=true

        this.setState(prevState => ({
            selectvalue: {
            ...prevState.selectvalue,          
            [i]: newvals
            }
            }))
    } else {

        this.setState(prevState => ({
            selectvalue: {
            ...prevState.selectvalue,          
            [i]: {                     
            ...prevState.selectvalue[i], 
            [e]: !this.state.selectvalue[i][e]         
            }
            }
            }))
        }
  }


     /// callback:  used by RADIO and CHECKBOX
    handleRadioCheckChange = (event) => {
      const target = event.target;
      if(target != null){
        let type = target.type
        let value = target.id
        const name = target.name;

        console.log('---}', name, value, type)

        this.toggleRadioCheck(name, value, type)
      }
    }


    /// used to generate the radio and checkbox items
    getSelectItems(item, type, qid){


      let itemkey = qid + item['value']

      if(type==="checkbox"){
        return(
        <div style={{marginRight:"15px", fontSize:"0.8em", fontWeight:"200", color:this.props.surveyprops.style.cardfoottextcolor}}>
        <CustomInput 
          type="checkbox" id={item['value']} 
          checked={this.state.selectvalue[qid][item['value']]} 
          label={item['label']} name={qid} key={itemkey} type={"checkbox"}
        ></CustomInput>
        </div>
        )}

        if(type==="radio"){
          return(
          <div style={{marginRight:"15px", fontSize:"0.8em", fontWeight:"200", color:this.props.surveyprops.style.cardfoottextcolor}}>
          <CustomInput 
            type="radio" id={item['value']} 
            checked={this.state.selectvalue[qid][item['value']]}  
            label={item['label']} name={qid} key={itemkey} type={"radio"}
          ></CustomInput>
          </div>
        )}
    }


  renderSelectType(){

    if (this.props.questionprops.selectiontype === "select"){
         return(
            <Select
            inputProps={{readOnly:true}}  /// this is used to hide the keyboard on mobile devices
            defaultValue={this.state.selectvalue[this.props.options[0].id]}
            onChange={this.handleSelectChange}
            options={this.props.options[0].selectoptions}
            styles={customStyles2(true)}  /// false means there is an error and will error style
          />
        )
        }
    if (this.props.questionprops.selectiontype === "selectcreatable"){
        return(
            <CreatableSelect
            defaultValue={this.state.selectvalue[this.props.options[0].id]}
            onChange={this.handleSelectChange}
            options={this.props.options[0].selectoptions}
            styles={customStyles2(true)}  
            />
        )
        }
    if (this.props.questionprops.selectiontype === "multiselect"){
        return(
            <Select
            inputProps={{readOnly:true}}  /// this is used to hide the keyboard on mobile devices
            defaultValue={this.state.selectvalue[this.props.options[0].id]}
            isMulti
            onChange={this.handleSelectChange}
            options={this.props.options[0].selectoptions}
            styles={customStyles2(true)}  
            />
        )
        }   
    if (this.props.questionprops.selectiontype === "multiselectcreatable"){
        return(
            <CreatableSelect
            isMulti
            defaultValue={this.state.selectvalue[this.props.options[0].id]}
            onChange={this.handleSelectChange}
            options={this.props.options[0].selectoptions}
            styles={customStyles2(true)}  
            />
        )
        }

    if (this.props.questionprops.selectiontype === "toggle"){
      return(
          <Toggle
          id={this.props.options[0].id}
          value={this.props.options[0].togglevalue}
          checked= {this.state.selectvalue[this.props.options[0].id]}
          icons={{unchecked: null}}
          className = {this.props.options[0].togglestyle}
          onChange = {() => this.handleToggleChange()}
      />
      )
    }


    if (this.props.questionprops.selectiontype ===  "textfield"){
      return(

        <Input 
          style={{fontSize:"1em", padding:"5px"}}
          name={this.props.options[0].id}
          type={"text"} 
          value={this.state.selectvalue}
          onChange={this.handleFieldChange}
          />
      
      )
    }

    if (this.props.questionprops.selectiontype === "text-area"){
    return(

      <Input 
          style={{fontSize:"1em", padding:"5px"}}
          name={this.props.options[0].id}
          type={"textarea"} 
          value={this.state.selectvalue}
          onChange={this.handleFieldChange}/>
    )
    }

    if (this.props.questionprops.selectiontype === "checkbox"){

      return(
      <Form >
            <FormGroup check inline name={this.props.options[0].id} onChange={this.handleRadioCheckChange} >
              {this.props.options[0].selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "checkbox", this.props.options[0].id))}
            </FormGroup>
      </Form>
      )
  }
    if (this.props.questionprops.selectiontype === "radio"){
      return(
      <Form >
            <FormGroup check inline name={this.props.options[0].id} onChange={this.handleRadioCheckChange} >
              {this.props.options[0].selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "radio", this.props.options[0].id))}
            </FormGroup>
      </Form>
      )
    }
    if (this.props.questionprops.selectiontype === "slider"){
      return(
        <div style={{marginTop:"15px", marginLeft:"15px", marginRight:"15px", marginBottom:"30px"}}>
        <Slider 
        marks={this.props.options[0].slidermarks}
        min={this.props.options[0].slidermin}
        max={this.props.options[0].slidermax}
        step={this.props.options[0].sliderstep}
        defaultValue={this.state.selectvalue[this.props.options[0].id]}
        onAfterChange={this.handleSelectChange}
        dotStyle={{borderColor: '#c8ced3'}}
        activeDotStyle={{borderColor: '#c8ced3'}}
        />
        </div>
      )
    }
    if (this.props.questionprops.selectiontype === "range"){
      return(
        <div style={{marginTop:"15px", marginLeft:"15px", marginRight:"15px", marginBottom:"30px"}}>
          <Range 
          marks={this.props.options[0].slidermarks}
          min={this.props.options[0].slidermin}
          max={this.props.options[0].slidermax}
          step={this.props.options[0].sliderstep} 
          defaultValue={this.state.selectvalue[this.props.options[0].id]}
          tipFormatter={value => `${value}`}
          onAfterChange={this.handleSelectChange}
          />
        </div>
      )
    }


  }


  render () {
    return (

      <div style={{display:"flex", alignItems:"stretch", flexWrap: "wrap", height:"100%", width:"100%"}}>

      <Card style={{width:this.getWidth("main"),
          backgroundColor:this.props.surveyprops.style.formbodycolor,
          color:this.props.surveyprops.style.formcardtextcolor,}}>
          
          {this.props.options[0].optionheader &&
            <CardHeader style={{backgroundColor:this.props.surveyprops.style.formheadcolor, color:this.props.surveyprops.style.formheadtextcolor}} >{this.props.options[0].optionheader}</CardHeader>
          }
      
        
        <CardBody style={{backgroundColor: this.props.questionprops.noselectcardcolor}}>


        <div style={{marginTop:"15px"}}>
        <Annotation
                src={this.state.options[0].imageSrc}
                alt='image to be annotated by respondent'
                annotations={this.state.annotations}
                allowTouch={true}
                value={this.state.annotation}
                onChange={this.onChange}
                onSubmit={this.onSubmit}
                />
        </div>
        </CardBody>



      </Card >

      {this.props.options[0].showfeatures &&  
      
      <Card style={{marginLeft:"5px", width:this.state.fcardwidth}}>
      
        <CardHeader style={{backgroundColor:this.props.surveyprops.style.formheadcolor, color:this.props.surveyprops.style.formheadtextcolor}} >{this.props.options[0].featureheader}</CardHeader>
        <CardBody>
              <FeatureList
              key={this.props.options[0].id}
              surveyprops={this.props.surveyprops}
              features={this.props.options[0].features}
              randomize={this.props.options[0].randomize}
              showprice={this.props.options[0].showprice}
              price={this.props.options[0].price}
              pricemetric={this.props.options[0].pricemetric}
          />
      </CardBody>
      <CardFooter style={{backgroundColor:this.props.surveyprops.style.formfootcolor, color: this.props.surveyprops.style.formfoottextcolor, paddingBottom:"0px"}}>
      
      <p style={{marginTop:"15px", marginBottom:"5px"}}><small>{this.props.options[0].helpmessage}</small></p>

        {this.renderSelectType()}



        </CardFooter>

      </Card>
      }
      </div>
    )
  }
}