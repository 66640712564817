import React, { Component } from "react";
import { Table, Card, CardBody} from 'reactstrap';
import ChoiceList from '../survey/ChoiceList'

/// SurveyList - is used as a list or grid to handle multiple questions (columns) with multiple subjects (rows)

const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

/// used to optionally shuffle rows
function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }
    return array;
    }

    export default class SurveyList extends Component {
        constructor(props) {
          super(props);
          let initialselections = {}
          let initializefalse = ['checkbox', 'radio']
          let val = {}
          let initialtotal = 0
          let initialize = {}
          let initialremaining = this.props.questionprops.allocationamount
          let newlistrow = this.props.list.listrow
          let validcolumns = {}
          let pageisvalid = false
          let hasallocation = false
          if (typeof this.props.savedpages != "undefined") {
              if(this.props.savedpages===true){
                  initialselections = this.props.savedresponses['selectvalue']
                  newlistrow = this.props.savedresponses['listrow']
                  initialremaining = this.props.savedresponses['remaining']
                  initialtotal = this.props.savedresponses['total']
                  validcolumns = this.props.savedresponses['validcolumns']
              }
              } else {
                    for (let r in this.props.list.listrow){
                        let rowid = this.props.list.listrow[r]['id']
                        for(let c in this.props.list.listcolumn){
                            let colid = this.props.list.listcolumn[c]['id']
                            val[colid] = this.props.list.listcolumn[c]['initial']
                            if(this.props.list.listcolumn[c]['type']==='allocation'){
                                hasallocation = true
                                val[colid] = 0
                            }
                            if(this.props.list.listcolumn[c]['type']==='range'){
                                val[colid] = [this.props.list.listcolumn[c]['slidermin'], this.props.list.listcolumn[c]['slidermax']]
                            }
                            if(this.props.list.listcolumn[c]['validationtype']==="none"){
                                validcolumns[colid]=true
                            } else {validcolumns[colid] = false}
                           
                            if (initializefalse.includes(this.props.list.listcolumn[c]['type']))
                            {
                            let startvals = {}
                            for (let s of this.props.list.listcolumn[c]['selectoptions']){
                                startvals[s['value']]=false
                                }
                                    val[colid]=startvals
                                }
                            initialize[rowid]=val
                        }
                    }
                    initialselections = initialize
                    if (this.props.list.listprops.randomize){
                        newlistrow = shuffle(newlistrow)}
                  }

          this.changeSelection = this.changeSelection.bind(this)
          this.changeAllocation = this.changeAllocation.bind(this)
          this.toggleRadioCheck = this.toggleRadioCheck.bind(this)
          this.changeDiscreteToggle = this.changeDiscreteToggle.bind(this)
          let numcols = this.props.list.listcolumn.length + 1
          this.state = {
              selectvalue: initialselections,
              listrow: newlistrow,
              listcolumn: this.props.list.listcolumn,
              listhead: this.props.list.listhead,
              listprops:this.props.list.listprops,
              remaining: initialremaining,
              total: initialtotal,
              validallocationamount:this.props.questionprops.allocationamount,
              isvalid: pageisvalid,
              validcolumns: validcolumns,
              pagetype:"SurveyList",
              selectiontype:"None",
              hasallocation: hasallocation,
              numcols: numcols
            };
          }
        
    /// TODO not sure if this validation check on mount is necessary...
    async componentDidMount() {
    this.pageValidation()
    }

    /// used to save state on going forward/back a page - so if you come back here the order (if random row) and selections you made are preserved
    componentWillUnmount(){
        this.props.saveSurvey(this.props.questionId, this.state, this.props.questionprops)
    }
      
    /// NOTE *** does NOTE reset page validation or column validation if you go from valid and then make invalid change
    /// this is checking of all the columns are valid
    pageValidation(){
        let checkitems = Object.values(this.state.validcolumns)
        let validcheck = checkitems.includes(false)
        this.setState({isvalid: !validcheck}, () => {this.props.handlePageValid(this.state.isvalid)})
    }

    /// this is checking if the individual column which was just updated is valid
    columnValidation(c){
        let validcheck = true
        let validtype = "none"
        let questtype = null
        let deepchecking = ['checkbox', 'radio']
        let colvals = []
        for(let j in this.props.list.listcolumn){
            if(this.props.list.listcolumn[j]['id']===c){
                validtype = this.props.list.listcolumn[j]['validationtype']
                questtype = this.props.list.listcolumn[j]['type']
            }
        }

        for(let i in this.state.selectvalue){
            if (deepchecking.includes(questtype)){
                let deepanswers = Object.values(this.state.selectvalue[i][c])
                let deepanswercheck = deepanswers.includes(true)
                colvals.push(deepanswercheck)
            } else {
            colvals.push(this.state.selectvalue[i][c])
            }
        }

        if(validtype==="all"){
            if (colvals.includes(false)){
                validcheck= false
            }
            if (colvals.includes(null)){
                validcheck = false
            }
        }

        if(validtype==="one")
            {
            validcheck = false
            if (colvals.includes(true)){
                validcheck= true
            } else {
            validcheck = colvals.some(function (el) {return el !== null})
            }
            }

        this.setState(prevState => ({validcolumns: {...prevState.validcolumns, [c]: validcheck}}), () => {this.pageValidation()} )
        
    }

    /// updating selection for r=row, c=column, v=value
    changeSelection(r, c, v){

        this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [r]: {                     
                ...prevState.selectvalue[r], 
                [c]: v         
                }
                }
                }),() => {this.columnValidation(c)})
        }

    /// updating selection for r=row, c=column, v=value, t=type (row - cant have more than one toggle in row, likewise for column, and can also enforce both for max-diff type)
    changeDiscreteToggle(r, c, v, t){

        console.log('change the discrete toggle values', r, c, v, t)


        if(t==="column" && v===true){
            for (let i of Object.keys(this.state.selectvalue)){

            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [i]: {                     
                ...prevState.selectvalue[i], 
                [c]: false      
                }
                }
                }))
            }
            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [r]: {                     
                ...prevState.selectvalue[r], 
                [c]: true     
                }
                }
                }),() => {this.columnValidation(c)})
        }

        if(t==="row" && v===true){
                for(let j of Object.keys(this.state.selectvalue[r])){
                    for(let k in this.props.list.listcolumn){
                        if(this.props.list.listcolumn[k]['id']===j){
                            if (this.props.list.listcolumn[k]['type']==="discrete-toggle"){
                                this.setState(prevState => ({
                                    selectvalue: {
                                    ...prevState.selectvalue,          
                                    [r]: {                     
                                    ...prevState.selectvalue[r], 
                                    [this.props.list.listcolumn[k]['id']]: false     
                                    }
                                    }
                                    }),() => {this.columnValidation(c)})
                                } 
                            }
                        }
                    }
                this.setState(prevState => ({
                    selectvalue: {
                    ...prevState.selectvalue,          
                    [r]: {                     
                    ...prevState.selectvalue[r], 
                    [c]: true      
                    }
                    }
                    }),() => {this.columnValidation(c)})
                }

            if(t==="both" && v===true){
                for (let i of Object.keys(this.state.selectvalue)){

                    this.setState(prevState => ({
                        selectvalue: {
                        ...prevState.selectvalue,          
                        [i]: {                     
                        ...prevState.selectvalue[i], 
                        [c]: false      
                        }
                        }
                        }),() => {this.columnValidation(c)})
                    }
                for(let j of Object.keys(this.state.selectvalue[r])){
                    for(let k in this.props.list.listcolumn){
                        if(this.props.list.listcolumn[k]['id']===j){
                            if (this.props.list.listcolumn[k]['type']==="discretetoggle"){
                                this.setState(prevState => ({
                                    selectvalue: {
                                    ...prevState.selectvalue,          
                                    [r]: {                     
                                    ...prevState.selectvalue[r], 
                                    [this.props.list.listcolumn[k]['id']]: false     
                                    }
                                    }
                                    }),() => {this.columnValidation(c)})
                                } 
                            }
                        }
                    }
                this.setState(prevState => ({
                    selectvalue: {
                    ...prevState.selectvalue,          
                    [r]: {                     
                    ...prevState.selectvalue[r], 
                    [c]: true      
                    }
                    }
                    }),() => {this.columnValidation(c)})
                }
        }

    /// updating allocation for r=row, c=column, v=value.  Calcs the total and remaining
    changeAllocation(r, c, v){

        let newtotal = v
        for (let x of Object.keys(this.state.selectvalue)){
          if (x !== r){
              for (let y of Object.keys(this.state.selectvalue[x])){
                  if(y===c){
                    newtotal = newtotal + this.state.selectvalue[x][y]
                  }
              }
            
          }
        }

        let newremain = this.state.allocation - newtotal
        let validcheck = false

       if (newremain < 0){
         v = v + newremain
         newtotal = newtotal + newremain
         newremain = newremain - newremain
        }

       if(newtotal === this.state.allocation){validcheck=true}

       if(this.props.questionprops.validationtype==="none"){validcheck=true}

       this.setState(prevState => ({
        selectvalue: {
        ...prevState.selectvalue,          
        [r]: {                     
        ...prevState.selectvalue[r], 
        [c]: v         
        }},
        total: newtotal,
        remaining: newremain,
        validallocationamount: newremain,
        }),() => {this.columnValidation(c)})

    }

    /// updating selection for r=row, c=column, v=value, t=type
    toggleRadioCheck(r, c, e, t){

        let val = e.indexOf(":")
        let updt = e.slice(val+1)

        console.log('toggle radio check in survey list', r, c, e, t, val, updt)

        if(t==='radio'){
            let newvals = {}
            for (let p in this.state.selectvalue[r][c])
                {
                newvals[p]=false
                }
            newvals[updt]=true

            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [r]: {                     
                ...prevState.selectvalue[r], 
                [c]: newvals

                }
                }
                }),() => {this.columnValidation(c)})
        } else {

            this.setState(prevState => ({
                selectvalue: {
                ...prevState.selectvalue,          
                [r]: {                     
                ...prevState.selectvalue[r], 
                [c]: {
                ...prevState.selectvalue[r][c], 
                [updt]: !prevState.selectvalue[r][c][updt]
                }
                }
                }
                }),() => {this.columnValidation(c)})
            }
    }

    
    /// Todo - make sure ordering is correct when changing items in array;  Prob better to make this an attribute of column
     getHeader(item){
         return(
             <th style={{width:this.state.numcols, fontSize:"1em", color:this.props.surveyprops.style.tableheadtext, fontWeight:"200"}}>
                 {item.header}
             </th>
         )
         }

         renderAllocationCard(){

            return(
              <div style={{display:"flex", marginLeft:"30px"}}>
                <Card style={{backgroundColor:this.props.surveyprops.style.cardheadcolor, color:this.props.surveyprops.style.cardheadtextcolor, height:"40px", width:"60px", marginRight:"5px"}}>
                  <p style={{fontSize:"2em", fontWeight:"600", marginLeft:"5px"}}>{this.state.total}</p>
                  <p style={{fontSize:"0.5em"}}>total</p>
                </Card>
                {this.state.remaining !== 0 &&
                <Card style={{backgroundColor:this.props.surveyprops.style.cardheadtextcolor, color:this.props.surveyprops.style.cardheadcolor, height:"40px", width:"60px", marginRight:"5px"}}>
                <p style={{fontSize:"2em", fontWeight:"600", marginLeft:"5px"}}>{this.state.remaining}</p>
                <p style={{fontSize:"0.5em"}}>to go</p>
                </Card>
                }
                 {this.state.remaining === 0 &&
                 <div style={{display:"flex"}}>
                <Card style={{backgroundColor:this.props.surveyprops.style.cardheadcolor, color:this.props.surveyprops.style.cardheadtextcolor, height:"40px", width:"60px", marginRight:"5px"}}>
                <p style={{fontSize:"2em", fontWeight:"600", marginLeft:"5px"}}>{this.state.remaining}</p>
                <p style={{fontSize:"0.5em"}}>to go</p>
                </Card>
                <div>
                   <span><i className="icon-check" style={{color:"#20a8d8", fontSize:"2em", marginLeft:"3px"}} ></i></span>
                </div>
                </div>
                }
        
              </div>
        
            )
          }
          


    /// calls ChoiceList to create each cell in the grid
    getListItem(item){
        return(
        <ChoiceList
            key={item.id}
            surveyprops={this.props.surveyprops}
            list={item}
            listprops={this.props.list.listprops}
            columns={this.props.list.listcolumn}
            remaining={this.state.remaining}
            validallocationamount={this.state.validallocationamount}
            selectvalue={this.state.selectvalue[item.id]}
            questionprops={this.props.questionprops}
            questionId={this.props.questionId}
            onSelectionChange={this.changeSelection}
            onAllocationChange={this.changeAllocation}
            onRadioCheckToggle={this.toggleRadioCheck}
            onDiscreteToggleChange={this.changeDiscreteToggle}
            />
        )
    }
      
      
         render(){
          return(
              <div style={{overflowX:"auto"}}>

            <Table className="survey-list-table">
                <thead className="survey-list-table thead">
                <tr>

                {this.props.list.listhead.map((item, key=item.id)=>this.getHeader(item))}

                </tr>

                </thead>
                <tbody style={{padding:"8px"}}>
                {this.state.listrow.map((item, key=item.id)=>this.getListItem(item))}
                </tbody>
            </Table>
        
        {this.state.hasallocation && this.renderAllocationCard()}

      </div>
          );
        }
      }