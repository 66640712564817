import React, { Component } from "react";
import { Button, Card, CardBody, CardImg, CardTitle, CardText, FormGroup, CardHeader, CardGroup, CardSubtitle, Col, Container, Row, CardDeck, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText} from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import CreatableSelect from 'react-select/creatable'
import { API } from "aws-amplify";

const img = "../assets/img/bee7.jpg"

const options = [
  { value: 'PM', label: 'Product Management' },
  { value: 'UX', label: 'UX/Design' },
  { value: 'MKT', label: 'Marketing' },
  { value: 'MC', label: 'Management Consulting' },
  { value: 'ENG', label: 'Engineering' },
  { value: 'FIN', label: 'Finance' },
  { value: 'SAL', label: 'Sales' },
  { value: 'CC', label: 'Customer Care' },
];

const customStyles = (isValid) => ({
  control: (styles: any) => ({
    ...styles,
    ...(!isValid && { borderColor: 'red' }),
  }),
});

export default class Contact extends Component {
    constructor(props) {
        super(props);
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.state = {
            submitted: false,
            contactname:null,
            contactorg:null,
            contactemail:null,
            contactrole:null,
            contactcomments:null,

          };      
      }

      handleFormChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
          [name]: value
        });
      }

      handleMultiChange = (newValue: any, actionMeta: any) => {
        this.setState(
          { contactrole: newValue });
      };

      handleValidSubmit() {

          API.put("contact", "/contact", {body:this.state});
          this.setState({submitted:true})
          console.log("contact: " + JSON.stringify(this.state));
       }

 render() {
   return(

    <div>

    <div className="breadcrumb">
      <li><a href="/">Home</a></li>
      <li>Contact</li>
    </div>

    <div style={{alignContent:"center", marginLeft:"30px", marginTop:"30px"}}>

    <Row>

      <Col sm="6">

      <CardGroup style={{marginBottom:"20px"}}>

        <Card >

        <CardHeader className='survey-card-header'>Please enter your contact information</CardHeader>

        <AvForm style={{marginTop:"10px", marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}} onValidSubmit={this.handleValidSubmit}>

            <h4>Name*</h4>
            <AvField name="contactname" type="name"  helpMessage="Please enter your first and last name" errorMessage="Invalid name" validate={{
              required: {value: true},
            }} 
            value={this.state.contactname} 
            onChange={this.handleFormChange}
            />
            
            <h4>Email*</h4>
            <AvField name="contactemail" type="email" helpMessage="Please enter your email address" validate={{
              required: {value: true, errorMessage: 'Please enter a valid email'},
            }} 
            value={this.state.contactemail} 
            onChange={this.handleFormChange}
            />

            <h4>Organization</h4>
            <AvField name="contactorg" type="text" helpMessage="Please enter the company you work for" validate={{
              required: {value: false}}} 
            value={this.state.contactorg} 
            onChange={this.handleFormChange}
            />

            <h4>Job Role</h4>
              <CreatableSelect
                  isMulti
                  onChange={this.handleMultiChange}
                  options={options}
                  styles={customStyles(true)}  /// false means there is an error
              />
              <p><small>Type to search, select or add your own item</small></p>

            <h4>Comments</h4>
            <AvField name="contactcomments" type="textarea" helpMessage="Please let us know what you'd like to talk about" validate={{
              required: {value: false}}} 
            value={this.state.contactcomments} 
            onChange={this.handleFormChange}
            />
          <FormGroup>
          {!this.state.submitted && <button className="btn btn-pill btn-lg btn-outline-dark">SUBMIT</button>}
          </FormGroup>
            </AvForm>

        </Card>

      <Card>

      <CardBody >            
        <img width="100%" src={img} alt="Contact Image" />

      </CardBody>

      </Card>

      </CardGroup>

      </Col>
      
      </Row>

      {this.state.submitted && <h4 style={{fontWeight:"300"}}>Thank you for your interest in Pricekit.  We will get back to you right away.</h4>}

    </div>

    </div>
   )

  }
 
} 
