import React, { Component } from "react";
import { Card, CardBody, CardHeader,  Col,  Row,} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';




const optionheader1 = "Reward Redemption Information"

export default class InputWidgetTour extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoading: true,
        field1: null,
        field2: null,
      };

    }
   
  async componentDidMount() {
    this.setState({ isLoading: false });
  }

    handleFormChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }


  render(){
  
    return (

       <div>

            <div className='survey-question-body'>

            <Row>

              <Col>

              <Card>
              
              <CardHeader className='survey-card-header'>{optionheader1}</CardHeader>

              <CardBody>

                <AvForm>
                  <Row>
                    <Col>
                    <h4>Name</h4>
                  <AvField name="field1" type="name"  helpMessage="Please enter your first and last name" errorMessage="Invalid name" validate={{
                      required: {value: true},

                    }} 
                    value={this.state.field1} 
                    onChange={this.handleFormChange}
                    />
                    </Col>
                    <Col>
                   <h4>Email</h4>
                  <AvField name="field2" type="email" helpMessage="Please enter your email address" validate={{
                      required: {value: true, errorMessage: 'Please enter a valid email'},

                    }} 
                    value={this.state.field2} 
                    onChange={this.handleFormChange}
                    />
                    </Col>
                  </Row>
                </AvForm>

              </CardBody>

              </Card>
                           
              </Col>

            </Row>

            </div>
            </div>
    );
  }
}