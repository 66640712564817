import React, { Component } from "react";
import {  Card, CardBody, CardHeader, CardFooter, Col, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Jumbotron, Input, CustomInput, Form, FormGroup} from 'reactstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import GifPlayer from 'react-gif-player';
import ReactPlayer from 'react-player';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Toggle from 'react-toggle'
import  'react-toggle/style.css'


/// FormCard - component that assembles each card with the items for the body

/// styling for react-select
const customStyles = (isValid) => ({
    control: (styles: any, state:any) => ({
      ...styles,
      padding: 5,
      fontSize: "smaller",
      ...(!isValid && { borderColor: 'red' }),
    }),
  });

/// styling for range and slider
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;
const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <Tooltip
        prefixCls="rc-slider-tooltip"
        overlay={value}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </Tooltip>
    );
  };

export default class FormCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isValid: false,
      };
    }
   
  async componentDidMount(){ 
  }

    /// callback to send option id (i) and the value of the event(e) back to parent component
    handleSelectionChange(i, e){
        this.props.onSelectionChange(i, e)
    }
    
    /// calls Selection Change callback:  Used by SELECT and MULTI --- not slider range here as it doesnt pass name via action
    handleSelectChange = (selectvalue:any, action: any) => {
        this.handleSelectionChange(action.name, selectvalue)
    };

    handleSliderChange(id, e) {

      console.log('slider update', id, e)

      this.handleSelectionChange(id, e)
    };



    /// calls Selection Change
    handleToggleSelect(e){
      this.handleSelectionChange(this.props.form.fields.id, e)
    }

    /// calls Selection Change callback:  Used by TEXT-FIELD and TEXT-AREA
    handleFieldChange = (event) => {
      const target = event.target;
      let value = target.value;
      const name = target.name;
      this.handleSelectionChange(name, value)
    }

    /// calls Selection Change callback:  Used by TOGGLE
    handleToggleChange(i){
      this.props.onSelectionChange(i, !this.props.selectvalue[i])
    }

    /// callback:  used by RADIO and CHECKBOX
    handleRadioCheckChange = (event) => {
      const target = event.target;


      if(target != null){
        let type = target.type
        let value = target.value
        const name = target.name;
        this.props.onRadioCheckToggle(name, value, type)
      }
    }


    /// used to generate the radio and checkbox items
    getSelectItems(item, type, qid){

      let itemkey = qid + item['value']
      if(type==="checkbox"){
        return(
        <div style={{marginRight:"15px", fontSize:"0.8em", fontWeight:"200", color:this.props.surveyprops.style.formcardtextcolor}}>
        <CustomInput 
          type="checkbox" id={itemkey} 
          checked={this.props.selectvalue[qid][item['value']]} 
          value={item['value']}
          label={item['label']} name={qid} key={itemkey} type={"checkbox"}
        ></CustomInput>
        </div>
        )}

        if(type==="radio"){
          return(
          <div style={{marginRight:"15px", fontSize:"0.8em", fontWeight:"200", color:this.props.surveyprops.style.formcardtextcolor}}>
          <CustomInput 
            type="radio" id={itemkey} 
            checked={this.props.selectvalue[qid][item['value']]}  
            label={item['label']} value={item['value']}
            name={qid} key={itemkey} type={"radio"}
          ></CustomInput>
          </div>
        )}
    }

    /// a function to render the map of list details used for content only items (per ContentCard)
    renderListItem(h, d, key){
      return(
      <ListGroupItem style={{borderColor:this.props.surveyprops.style.contentlistborder,
        backgroundColor:this.props.surveyprops.style.contentlistbackground,
        color:this.props.surveyprops.style.contentlisttext.header,
        marginBottom:"10px",
       padding:"0px"}}
        key={key}>
      <ListGroupItemHeading style={{fontSize:"1em", fontWeight:"300"}}>{h}</ListGroupItemHeading>
      <ListGroupItemText style={{fontSize:"0.7em", fontWeight:"500"}}>{d}</ListGroupItemText>
      </ListGroupItem>
      )
    }


    showField(target, test){

      if(test === null){return true}

      if(typeof target === 'undefined'){ return false}

      if (test === target){return true}

      if(typeof target ==='object'){

        if(target !== null){

          /// if the test is based on values from a selection field

          if(typeof test.value !== 'undefined'){

            if(Array.isArray(target)){

              let targetlist = []
              
              for(let i of target){targetlist.push(i.value)}
              
              if(targetlist.includes(test.value)){return true} else {return false}
            }

            else {

              if(test.value === target.value){return true} else {return false}

            }
          }

          /// check for radio/checkbox true values


          for (let x of Object.keys(test)){


            if(typeof target[x] !== 'undefined'){


              if(target[x] === test[x]){
                return true
              }
            }
          }
        }
        return false
      }
    }


  /// this determines which selection component to use in the cards

  renderSelectType(field){

  if(this.showField(this.props.selectvalue[field['showif']['id']], field['showif']['value'])){

    if (field['type'] === "select"){
         return(
           <div className="survey-form-card-label">
            <p><small>{field.header}</small></p>

            <Select
            readOnly = { true}
            isSearchable={ false }
            inputProps={{readOnly:true,  readOnly: 'true'}}  /// this is used to hide the keyboard on mobile devices
            defaultValue={this.props.selectvalue[field.id]}
            onChange={this.handleSelectChange}
            options={field.selectoptions}
            name={field.id}
            styles={customStyles(true)}  /// false means there is an error and will error style
          />
        </div>
        )
        }
    if (field['type']  === "selectcreatable"){
        return(
          <div className="survey-form-card-label">
          <p><small>{field.header}</small></p>
         
            <CreatableSelect
            defaultValue={this.props.selectvalue[field.id]}
            onChange={this.handleSelectChange}
            options={field.selectoptions}
            name={field.id}
            styles={customStyles(true)}  
            />
          </div>
        )
        }
    if (field['type']  === "multiselect"){
        return(
          <div className="survey-form-card-label">
            <p><small>{field.header}</small></p>
         
            <Select
            readOnly = { true}
            isSearchable={ false }
            inputProps={{readOnly:true}}
            defaultValue={this.props.selectvalue[field.id]}
            isMulti
            onChange={this.handleSelectChange}
            options={field.selectoptions}
            name={field.id}
            styles={customStyles(true)}  
            />
          </div>
        )
        }   
    if (field['type']  === "multiselectcreatable"){
        return(
          <div className="survey-form-card-label">
          <p><small>{field.header}</small></p>
         
            <CreatableSelect
            isMulti
            defaultValue={this.props.selectvalue[field.id]}
            onChange={this.handleSelectChange}
            options={field.selectoptions}
            name={field.id}
            styles={customStyles(true)}  
            />
          </div>
        )
        }
    if (field['type']  === "toggle"){
      return(
        <div className="survey-form-card-label">
        <p><small>{field.header}</small></p>
       
          <Toggle
          id={field.id}
          value={field.togglevalue}
          checked= {this.props.selectvalue[field.id]}
          icons={{unchecked: null}}
          className = {field.togglestyle}
          onChange = {() => this.handleToggleChange(field.id)}
      />
      </div>
      )
    }
    if (field['type']  === "textfield"){
      return(
        <div className="survey-form-card-label">
        <p><small>{field.header}</small></p>

        <Input 
          style={{fontSize:"1em", padding:"5px"}}
          name={field.id}
          type={field.texttype} 
          value={this.props.selectvalue[field.id]}
          onChange={this.handleFieldChange}
          />
      
      </div>
      )
    }
    if (field['type']  === "textarea"){
    return(
      <div className="survey-form-card-label">
      <p><small>{field.header}</small></p>
     
      <Input 
          style={{fontSize:"1em", padding:"5px"}}
          name={field.id}
          type={"textarea"} 
          value={this.props.selectvalue[field.id]}
          onChange={this.handleFieldChange}/>

    </div>
    )
    }

    if (field['type']  === "checkbox"){

      let showinline = "true"
      if (field.selectoptions.length >4){
          showinline = "false"
      }
    
      return(
        <div className="survey-form-card-label">
        <p><small>{field.header}</small></p>

        <Form style={{marginBottom:"15px"}}>
          <FormGroup check inline={showinline} name={field.id} onChange={this.handleRadioCheckChange} style={{fontSize:"0.8em", fontWeight:"400"}}>
              {field.selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "checkbox", field['id']))}
          </FormGroup>
        </Form>
       
      </div>
      )
  }
    if (field['type']  === "radio"){

      let showinline = "true"
      if (field.selectoptions.length >4){
          showinline = "false"
      }

      return(
        <div className="survey-form-card-label">
        <p><small>{field.header}</small></p>
       
        <Form style={{marginBottom:"15px"}}>
          <FormGroup check inline={showinline} name={field.id} onChange={this.handleRadioCheckChange} style={{fontSize:"0.8em", fontWeight:"400"}}>
              {field.selectoptions.map((item, key=item.id)=>this.getSelectItems(item, "radio", field['id']))}
          </FormGroup>
        </Form>
      </div>
      )
    }

    if (field['type']  === "slider"){

      return(
        <div className="survey-form-card-label">
        <p><small>{field.header}</small></p>
        <div style={{marginTop:"15px", marginLeft:"30px", marginRight:"30px", marginBottom:"30px"}}>
       <Slider 
        marks={field.slidermarks}
        min={field.slidermin}
        max={field.slidermax}
        step={field.sliderstep}
        defaultValue={this.props.selectvalue[field.id]}

        onAfterChange={(e)=>this.handleSliderChange(field['id'], e)}

        dotStyle={{borderColor: '#c8ced3'}}
        activeDotStyle={{borderColor: '#c8ced3'}}        
        />
        </div>
        </div>
      )
    }
    if (field['type']  === "range"){
      return(
        <div className="survey-form-card-label">
        <p><small>{field.header}</small></p>
        <div style={{marginTop:"15px", marginLeft:"30px", marginRight:"30px", marginBottom:"30px"}}>
          <Range 
          marks={field.slidermarks}
          min={field.slidermin}
          max={field.slidermax}
          step={field.sliderstep} 
          defaultValue={this.props.selectvalue[field.id]}
          tipFormatter={value => `${value}`}

          onAfterChange={(e)=>this.handleSliderChange(field['id'], e)}

          />
        </div>
        </div>
      )
    }
    if (field['type'] === "img"){
      return(
        <div style={{display:"flex", justifyContent:"center", marginTop:"5px", marginBottom:"5px"}}>
          <img style={{maxWidth:"100%"}} src={field.img} alt="Survey Image" />
        </div>
      )
  }


  if (field['type'] === "bigimg"){
    return(
        <div tag="a"  onClick={() => this.props.toggleLightbox(field['img'], field['imageTitle'], field['imageCaption'])} style={{cursor: "pointer", marginBottom:"30px"}} >
        <img width="100%" src={field['img']} alt="Survey Image" />
        </div>
    )
}

if (field['type'] === "gif"){
        return(
            <div style={{cursor: "pointer", marginBottom:"30px"}}>
            <GifPlayer
                gif={field['gif']}
                width="100%"
                />
            <p  style={{color:this.props.surveyprops.style.formcardtextcolor, fontSize:"0.5em"}}>Click to play</p>
            </div>
    )
}

if (field['type'] === "video"){
    return(
        <div style={{display:"flex", flexGrow:"1", marginBottom:"30px"}}>
        <ReactPlayer
            url={field['video']}
            controls={true}
            muted={true}
            width='100%'
            height='100%'
            />
        </div>
        )
}

  if (field['type'] === "html"){
    return(
            <div dangerouslySetInnerHTML={{ __html: field.text }} />
    )
    }


  if (field['type'] === "contentlist"){
      return(
          <ListGroup className="survey-form-card-text">
            {field.list.map((item, key=item.id)=>this.renderListItem(item.header, item.detail, key))}
          </ListGroup>
          )
  }

  if (field['type']=== "jumbotron"){
      return(
          <Jumbotron fluid>
      <div>
          <h1>
              {field.headline}
          </h1>
      </div>
      <div>
          <h4>
              {field.subheadline}
          </h4>
      </div>
          </Jumbotron>
          )
  }

  if (field['type'] === 'jumbotron2'){
      return(
          <Jumbotron className="survey-content-jumbotron2"
          style={{
            backgroundColor:this.props.surveyprops.style.jumbobackground,
            color:this.props.surveyprops.style.jumbotrontext,
          }}
          key={field.id}>
              <h1>{field.headline}</h1>
              <h4>{field.subheadline}</h4>
              <hr  style={{borderColor:this.props.surveyprops.style.jumboline }}/>
              <p style={{marginTop:"15px"}}>{field.bodytext}</p>
          </Jumbotron>
      )
  }


  }
}

  render(){

    return(

        <Card className="survey-form-card" style={{backgroundColor:this.props.surveyprops.style.formbodycolor, maxWidth:this.props.mwidth, minWidth:this.props.surveyprops.style.mincardwidth}}>
            
            {this.props.card.optionheader &&
          <CardHeader className="survey-form-card-header" style={{backgroundColor:this.props.surveyprops.style.formheadcolor, color:this.props.surveyprops.style.formheadtextcolor}}>{this.props.card.optionheader}</CardHeader>
            }
            
          <CardBody  style={{color:this.props.surveyprops.formcardtextcolor, marginBottom:"15px"}}>

          {this.props.card.content.map((content, key=content.id)=>this.renderSelectType(content))}
        
          </CardBody>

          {this.props.card.showfooter &&
          <CardFooter className="survey-form-card-footer" style={{backgroundColor:this.props.surveyprops.style.formfootcolor, color: this.props.surveyprops.style.formfoottextcolor, paddingBottom:"0px"}}>

            <p style={{marginTop:"15px"}}><small>{this.props.card.helpmessage}</small></p>

          </CardFooter>
          }

        </Card>           

    );
  }
}