import React, { Component } from "react";
import {  Card, CardBody,  CardHeader,  Col,  Row,} from 'reactstrap';

import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';


const rangeslector = "Please select the range of discount you expect 'Gold' status to get"

const optionheader1 = "Price Expectation"


const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;
const wrapperStyle = {  margin: 20 };

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

export default class SliderWidgetTour extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoading: true,
        selectedOption: null,
        values: null

      };
    }
   
  async componentDidMount() {
    this.setState({ isLoading: false });
  }

  onSliderChange = (value) => {
    this.setState({values: value});
  }

  render(){


    return (


       <div>

            <div className='survey-question-body'>

            <Row>

              <Col>

              <Card>
              
              <CardHeader className='survey-card-header'>{optionheader1}</CardHeader>
              
              <CardBody >            
                    <h4>{rangeslector}</h4>
                    <div style={wrapperStyle}>
                    <Range 
                        min={0} 
                        max={100} 
                        defaultValue={[25, 75]}
                        marks={{ 0: 0, 25: 25, 50: 50, 75: 75, 100: 100 }} 
                        tipFormatter={value => `${value}%`}
                        onAfterChange={this.onSliderChange}
                        />
                    </div>
                    <p><small>Drag and move the handles anywhere along the range</small></p>
              
              </CardBody>

              </Card>
                           
              </Col>

            </Row>

            </div>
            </div>
    );
  }
}