export default {
    MAX_ATTACHMENT_SIZE: 50000000,

    s3: {
    REGION: "us-east-1",
    BUCKET: "pricekit-survey"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://k7knh5dn90.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_halvBamjE",
    APP_CLIENT_ID: "2hamipbtkkacrrq1jmr6o4luou",
    IDENTITY_POOL_ID: "us-east-1:10a5544f-7e3a-4b7d-bb60-1594115835e2"
  }
};
