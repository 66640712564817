import React, { Component, Fragment, Suspense } from "react";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import * as router from 'react-router-dom';
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, } from 'reactstrap';
import ReactGA from 'react-ga';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CompRoutes from "./Routes";
import routes from './routes2';
import Survey from "./survey/Survey"
import "./App.scss";
import logo from './assets/img/brand/logo.svg'
import sygnet from './assets/img/brand/logo.svg'

import {
   AppHeader,
  AppBreadcrumb2 as AppBreadcrumb, AppSidebarNav2 as AppSidebarNav, AppNavbarBrand, 
} from '@coreui/react';


const DefaultFooter = React.lazy(() => import('./containers/DefaultLayout/DefaultFooter'));
const DefaultHeader = React.lazy(() => import('./containers/DefaultLayout/DefaultHeader'));

//Used to determine if user is going to a survey

function getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
      vars[key] = value;
  });
  return vars;
}


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      activeSurvey: false,
      surveyId: null,
      tracking:null
    };
  }

  
  // component did mount will check if authenticated even though not yet required for public web

  async componentDidMount() {

    ReactGA.initialize('UA-156179221-1')
    ReactGA.pageview('/')

    const s = getUrlVars()
    if ("S" in s) {

      this.setState({ surveyId: s['S'], activeSurvey: true, tracking:s})
    }
  
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
    }
    catch(e) {
      this.setState({ isAuthenticating: false });
      }




  }

  loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>


  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
  }

  signOut(e) {
    e.preventDefault()
    this.props.history.push('/login')
  }

  
  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      isAuthenticating:  this.state.isAuthenticating,
    };


    // this is what will render if the end user is going for a survey
    // *******   SURVEY   *******


    // TODO - the content pages that show in the links

    if (this.state.activeSurvey === true){
      return (
          <div className = "app">
           <AppHeader fixed>
            <Suspense  fallback={this.loading()}>
            <React.Fragment>
              <AppNavbarBrand
               full={{ src: logo, width: 89, height: 25, alt: 'Pricekit Logo' }}
               minimized={{ src: sygnet, width: 30, height: 30, alt: 'Pricekit Logo' }}
              />
              <Nav className="ml-auto" navbar>

              <UncontrolledDropdown nav direction="down">
                <DropdownToggle nav>
                  <img src={'../../assets/img/bee.png'} alt="pricekit bee icon" />
                </DropdownToggle>
              </UncontrolledDropdown>
              </Nav>
              </React.Fragment>
            </Suspense>
          </AppHeader>
                <ToastContainer
                autoClose = {5000}
                toastClassName = "survey-toast"
                bodyClassName = "survey-toast-text"
                />
                <Survey 
                  surveyId={this.state.surveyId}
                  tracking={this.state.tracking}
                  />
          
          </div>
        )
      }


      /// this will render if the user is normal public web visitor
      ///   ********     PUBLIC WEB     ***********
      /// renders the sticky navbar and then pushes to Home by default due to router in COMPROUTES

      return (
        <div className="app">
        <AppHeader fixed>
          <Suspense  fallback={this.loading()}>
          <React.Fragment>
            <AppNavbarBrand
              full={{ src: logo, width: 89, height: 25, alt: 'Pricekit Logo'}}
              minimized={{ src: sygnet, width: 30, height: 30, alt: 'Pricekit Logo'}}
            />

            <Nav className="ml-auto" navbar>

               <a href="https://app.pricekit.io"><button className="btn btn-pill btn-lg btn-outline-dark" >LOG IN</button></a> 

               <UncontrolledDropdown nav direction="down">

                <DropdownToggle nav>
                  <img src={'../../assets/img/bee.png'} alt="pricekit bee icon" />
                </DropdownToggle>

                <DropdownMenu right>
                  <DropdownItem header tag="div" className="text-center"><strong>Account</strong></DropdownItem>
                  <DropdownItem href="https://app.pricekit.io"><i className="fa fa-lock"></i> Log-In</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>

            </React.Fragment>
          </Suspense>
        </AppHeader>

        <div className="app-body">
          <main className="main">

              <CompRoutes childProps={childProps}/>

          </main>
        </div>
        </div>
      )
    }
}
export default withRouter(App);
