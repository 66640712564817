import React, { Component } from 'react';
import { Collapse, CardBody, Card, CardHeader } from 'reactstrap';


export default class FAQ extends Component {
  constructor(props) {
      super(props);
      this.toggle = this.toggle.bind(this);
      this.state = { collapse: 0, cards: [1, 2, 3, 4, 5] };
    }

    toggle(e) {
      let event = e.target.dataset.event;
      this.setState({ collapse: this.state.collapse === Number(event) ? 0 : Number(event) });
    }
    render() {
      const {collapse} = this.state;
      return (
        <div>
          <div className="breadcrumb">
            <li>
            <a href="/">Home</a>
            </li>
            <li>
            FAQ
            </li>
          </div>
        <div className="container">
            <h1 className="page-header">Frequently Asked Questions</h1>
                <div>
                <Card style={{ marginBottom: '1rem', fontSize: '150%'}} key={1}>
                  <CardHeader onClick={this.toggle} data-event={1}>Is Pricekit really free ?</CardHeader>
                  <Collapse isOpen={collapse === 1}>
                  <CardBody>
                  Yes!  Our free plan is designed so that you can use it for a wide range of use-cases without having to spend any money.  
                  Note though, that with this offer you can only run one survey at a time, and each of these is limited to a maximum of 30 respondents.  
                  This means that some types of projects you won’t be able to do – if you have more than 3 choices, 3 segments or need detailed price elasticity 
                  analytics you won’t be able to do that with a 30 person max sample.  Of course, we offer a variety of paid plans that are more than suitable for such projects.
                  </CardBody>
                  </Collapse>
                </Card>
                <Card style={{ marginBottom: '1rem', fontSize: '150%'  }} key={2}>
                <CardHeader onClick={this.toggle} data-event={2}>How do I send a survey ?</CardHeader>
                <Collapse isOpen={collapse === 2}>
                <CardBody>
                There are four primary methods for getting a survey into the hands of potential respondents.  The first is to include a survey link inside an email you send to a list of contacts.  
                Typically this would be a list pulled from your CRM application, or alternatively from a third party list/panel provider.   Secondly, you can share a survey link on a 
                landing page hosted on your own web site.  Third, you can share this link on internal messaging applications, like Teams or Slack.  This is a great way to get internal 
                feedback from colleagues.  Finally, you can create an add or post which includes this link on Facebook, Google, Twitter and LinkedIn – typically targeted to lookalike 
                audiences to get in front of the segments most relevant for your survey.
                </CardBody>
                </Collapse>
                </Card>
                <Card style={{ marginBottom: '1rem', fontSize: '150%' }} key={3}>
                  <CardHeader onClick={this.toggle} data-event={3}>What kinds of questions can I ask ?</CardHeader>
                  <Collapse isOpen={collapse === 3}>
                  <CardBody>
                  A survey can include a wide range of question types, including free form text, selection from drop downs, check boxes and radio buttons, boolean toggles, and a variety of matrix 
                  question formats.  Of particular interest to product designers and feature testers, we can show images, video clips and gifs so respondents can more accurately assess your 
                  designs.  In addition to getting choice preferences we can also enable forced ranking and chip allocation type questions too.
                  </CardBody>
                  </Collapse>
                </Card>
                <Card style={{ marginBottom: '1rem', fontSize: '150%'  }} key={4}>
                <CardHeader onClick={this.toggle} data-event={4}>Does this integrate with Salesforce ?</CardHeader>
                <Collapse isOpen={collapse === 4}>
                <CardBody>
                Today we can provide you with csv files that you can use to manually integrate with CRM data.  We are working with API and automatic integration methods, which we anticipate to 
                be available in H1 2020.
                </CardBody>
                </Collapse>
                </Card>
                <Card style={{ marginBottom: '1rem', fontSize: '150%' }} key={5}>
                  <CardHeader onClick={this.toggle} data-event={5}>How do you track respondents ?</CardHeader>
                  <Collapse isOpen={collapse === 5}>
                  <CardBody>
                  As a general rule, Pricekit does not track information associated individual respondents. For most basic surveys we do drop a cookie which we use to ensure that 
                  they don’t re-take the same survey, and we use normal web session information to provide the best survey user experience we can.  Otherwise we don’t have any 
                  personally identifying information about a respondent.However, some customers have their own CRM data file and wish to append Pricekit survey response 
                  information to it.  In these cases, customers can create a unique code for a person in their database and pass that to us so we can tag survey response data with 
                  it.  Note that this is non-PII information, but it can allow for our customers to track survey responses to individuals for which they directly invite with their 
                  own unique codes.
                  </CardBody>
                  </Collapse>
                </Card>
                <Card style={{ marginBottom: '1rem', fontSize: '150%' }} key={6}>
                  <CardHeader onClick={this.toggle} data-event={6}>How do your analytics work ?</CardHeader>
                  <Collapse isOpen={collapse === 6}>
                  <CardBody>
                  We pride ourselves as being top-notch analytic model builders.  This is the basis for extracting insight from survey data so you can make smart decisions.  At 
                  the heart of our analytics are a set of statistical algorithms related to discrete choice modeling, conjoint analysis and Maxdiff approaches.  These are 
                  long-proven ways for working with the types of data you collect for pricing, packaging, and concept testing projects.
                  </CardBody>
                  </Collapse>
                </Card>
                <Card style={{ marginBottom: '1rem', fontSize: '150%' }} key={7}>
                  <CardHeader onClick={this.toggle} data-event={7}>Can I test a survey before it goes live ?</CardHeader>
                  <Collapse isOpen={collapse === 7}>
                  <CardBody>
                  Yes, after creating a survey you can access it in a test-only mode so you can see the experience your respondents will have before the survey is live.
                  </CardBody>
                  </Collapse>
                </Card>
                <Card style={{ marginBottom: '1rem', fontSize: '150%' }} key={8}>
                  <CardHeader onClick={this.toggle} data-event={8}>How long can a survey run ?</CardHeader>
                  <Collapse isOpen={collapse === 8}>
                  <CardBody>
                  If you have a paid subscription offering with Pricekit surveys can remain active for up to a year.  For per-project and free offerings, a survey can be active for a maximum of 90 days.
                  </CardBody>
                  </Collapse>
                </Card>
              </div>
              </div>
          </div>
      );
    }
}
