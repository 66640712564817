import React, { Component } from "react";
import { Card, CardBody,CardHeader,  Col,  Row, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText,} from 'reactstrap';
import GifPlayer from 'react-gif-player';
import Lightbox from 'react-image-lightbox';
import ReactPlayer from 'react-player';
import 'react-image-lightbox/style.css'; 

const bestcolor = '#4dbd74';
const worstcolor = '#f86c6b'
const noselectcolor = '#FFF'
const buttonname = "BEST OPTION"
const worstbutton = "WORST OPTION"

const questname = "Bring your concepts and designs to life."
const questexplain = "Sometimes you need more than text to illustrate the ideas you want customers to provide feedback on."
const questexplain2 = "We offer a lightbox to show images at full size with pan and zoom as well as animated gifs and full videos too."

const imgTitle = "The Mega Bee";
const imgCaption = "A concept that is both amazing and terrifying at the same time"


const gif2 = 'https://media.giphy.com/media/gO9ArgdDSFPMs/source.gif'
const img1 = "../assets/img/robot-bee.jpg"
const img1big = "../assets/img/robot-bee.jpg"
const vid3 = 'https://www.youtube.com/watch?v=dEbLeuUIaHI'

const optionheader1 = "Mega Bee"
const optionheader2 = "Robo-Bee"
const optionheader3 = "Robo-Bee 2.0"


const option1_listheader1 = "Capabilities"
const option1_listdetail1 = "Self organizing and self-replicating long-range robot"
const option1_listheader2 = "Viability"
const option1_listdetail2 = "Requires advances in material science, sensor software and new source of power"
const option1_listheader3 = "Risks"
const option1_listdetail3 = "Potential to go rogue and enslave humanity"


const option2_listheader1 = "Capabilities"
const option2_listdetail1 = "Tiny, light-weight and low cost indoor drone"
const option2_listheader2 = "Viability"
const option2_listdetail2 = "First flight in 2013, backed by Harvard researchers"
const option2_listheader3 = "Risks"
const option2_listdetail3 = "Fragile and no over-the-air software updates"


const option3_listheader1 = "Capabilities"
const option3_listdetail1 = "Medium range robot that can fly and go under water"
const option3_listheader2 = "Viability"
const option3_listdetail2 = "Initial prototype built in 2017, with incremental advances since"
const option3_listheader3 = "Risks"
const option3_listdetail3 = "Cost prohibitive to make at scale"



export default class ComboSurveyTour extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoading: true,
        showImage: false,
        imageSrc: null,
        imageTitle: imgTitle,
        imageCaption: imgCaption,
        choice1best: false,
        select1: noselectcolor,
        choice2best: false,
        select2: noselectcolor,
        choice3best: false,
        select3: noselectcolor,
        choice4best: false,
        select4: noselectcolor,
        choice1worst: false,
        choice2worst: false,
        choice3worst: false,
        choice4worst: false,
      };
    }
   
  async componentDidMount() {
    this.setState({ isLoading: false });
  }

  toggleBest(s){
    
    if(s===1){
          this.setState({
              choice1best: true,
              choice1worst: false,
              select1: bestcolor,
            });
            if (this.state.choice2best===true){
              this.setState({
                choice2best: false,
                select2: noselectcolor,
              })}
            if (this.state.choice3best===true){
              this.setState({
                choice3best: false,
                select3: noselectcolor,
              })}           
            if (this.state.choice4best===true){
              this.setState({
                choice4best: false,
                select4: noselectcolor,
              })}
            if (this.state.choice5best===true){
              this.setState({
                choice5best: false,
                select5: noselectcolor,
              })}
          }
      
    if(s===2){
          this.setState({
            choice2best: true,
            choice2worst: false,
            select2: bestcolor,
          });
          if (this.state.choice1best===true){
            this.setState({
              choice1best: false,
              select1: noselectcolor,
            })}
          if (this.state.choice3best===true){
            this.setState({
              choice3best: false,
              select3: noselectcolor,
            })}           
          if (this.state.choice4best===true){
            this.setState({
              choice4best: false,
              select4: noselectcolor,
            })}
          if (this.state.choice5best===true){
            this.setState({
              choice5best: false,
              select5: noselectcolor,
            })}
          }
        
    if(s===3){
          this.setState({
            choice3best: true,
            choice3worst: false,
            select3: bestcolor,
          });
          if (this.state.choice1best===true){
            this.setState({
              choice1best: false,
              select1: noselectcolor,
            })}
          if (this.state.choice2best===true){
            this.setState({
              choice2best: false,
              select2: noselectcolor,
            })}           
          if (this.state.choice4best===true){
            this.setState({
              choice4best: false,
              select4: noselectcolor,
            })}
          if (this.state.choice5best===true){
            this.setState({
              choice5best: false,
              select5: noselectcolor,
            })}
          }

    if(s===4){
          this.setState({
            choice4best: true,
            choice4worst: false,
            select4: bestcolor,
          });
          if (this.state.choice1best===true){
            this.setState({
              choice1best: false,
              select1: noselectcolor,
            })}
          if (this.state.choice2best===true){
            this.setState({
              choice2best: false,
              select2: noselectcolor,
            })}           
          if (this.state.choice3best===true){
            this.setState({
              choice3best: false,
              select3: noselectcolor,
            })}
          if (this.state.choice5best===true){
            this.setState({
              choice5best: false,
              select5: noselectcolor,
            })}
        }

      if(s===5){
        this.setState({
          choice5best: true,
          choice5worst: false,
          select5: bestcolor,
        });
        if (this.state.choice1best===true){
          this.setState({
            choice1best: false,
            select1: noselectcolor,
          })}
        if (this.state.choice2best===true){
          this.setState({
            choice2best: false,
            select2: noselectcolor,
          })}           
        if (this.state.choice3best===true){
          this.setState({
            choice3best: false,
            select3: noselectcolor,
          })}
        if (this.state.choice4best===true){
          this.setState({
            choice4best: false,
            select4: noselectcolor,
          })}
      }

      return
  }

  toggleWorst(s){
    
    if(s===1){
          this.setState({
            choice1worst: true,
            choice1best: false,
            select1: worstcolor,
          });
          if (this.state.choice2worst===true){
            this.setState({
              choice2worst: false,
              select2: noselectcolor,
            })}
          if (this.state.choice3worst===true){
            this.setState({
              choice3worst: false,
              select3: noselectcolor,
            })}           
          if (this.state.choice4worst===true){
            this.setState({
              choice4worst: false,
              select4: noselectcolor,
            })}
          if (this.state.choice5worst===true){
            this.setState({
              choice5worst: false,
              select5: noselectcolor,
            })}
          }
      
    if(s===2){
          this.setState({
            choice2worst: true,
            choice2best: false,
            select2: worstcolor,
          });
          if (this.state.choice1worst===true){
            this.setState({
              choice1worst: false,
              select1: noselectcolor,
            })}
          if (this.state.choice3worst===true){
            this.setState({
              choice3worst: false,
              select3: noselectcolor,
            })}           
          if (this.state.choice4worst===true){
            this.setState({
              choice4worst: false,
              select4: noselectcolor,
            })}
          if (this.state.choice5worst===true){
            this.setState({
              choice5worst: false,
              select5: noselectcolor,
            })}
        }
        
    if(s===3){
          this.setState({
            choice3worst: true,
            choice3best: false,
            select3: worstcolor,
          });
          if (this.state.choice1worst===true){
            this.setState({
              choice1worst: false,
              select1: noselectcolor,
            })}
          if (this.state.choice2worst===true){
            this.setState({
              choice2worst: false,
              select2: noselectcolor,
            })}           
          if (this.state.choice4worst===true){
            this.setState({
              choice4worst: false,
              select4: noselectcolor,
            })}
          if (this.state.choice5worst===true){
            this.setState({
              choice5worst: false,
              select5: noselectcolor,
            })}
        }

    if(s===4){
          this.setState({
            choice4worst: true,
            choice4best: false,
            select4: worstcolor,
          });
          if (this.state.choice1worst===true){
            this.setState({
              choice1worst: false,
              select1: noselectcolor,
            })}
          if (this.state.choice2worst===true){
            this.setState({
              choice2worst: false,
              select2: noselectcolor,
            })}           
          if (this.state.choice3worst===true){
            this.setState({
              choice3worst: false,
              select3: noselectcolor,
            })}
          if (this.state.choice5worst===true){
            this.setState({
              choice5worst: false,
              select5: noselectcolor,
            })}
        }

      if(s===5){
        this.setState({
          choice5worst: true,
          choice5best: false,
          select5: worstcolor,
        });
        if (this.state.choice1worst===true){
          this.setState({
            choice1worst: false,
            select1: noselectcolor,
          })}
        if (this.state.choice2worst===true){
          this.setState({
            choice2worst: false,
            select2: noselectcolor,
          })}           
        if (this.state.choice3worst===true){
          this.setState({
            choice3worst: false,
            select3: noselectcolor,
          })}
        if (this.state.choice4worst===true){
          this.setState({
            choice4worst: false,
            select4: noselectcolor,
          })}
      }

      return

    }

  render(){
    const { showImage, imageSrc, imageTitle, imageCaption } = this.state;
    return(  

        <div>
            <div>
            {showImage && (
              <Lightbox
                mainSrc={this.state.imageSrc}
                enableZoom={true}
                imageTitle={this.state.imageTitle}
                imageCaption={this.state.imageCaption}
                onCloseRequest={() => this.setState({ showImage: false })}
              />
            )}

            </div>

            <div>

            <div className='tour-question-header'>
                <h2>{questname}</h2>
                <h3 style={{marginBottom:"10px"}}>{questexplain}</h3>
                <h4>{questexplain2}</h4>
            </div>

            <div className='survey-question-body'>
            <p style={{marginTop:"10px", color:"#20a8d8"}}>Click on each to try it:</p>

            <Row>

              <Col>

              <Card>
              
              <CardHeader className='survey-card-header'>{optionheader1}</CardHeader>
 
              <CardBody style={{backgroundColor: this.state.select1 }} > 

                <div tag="a"  onClick={() => this.setState({ showImage: !this.state.showImage, imageSrc: img1big, imageTitle: imgTitle, imageCaption: imgCaption })} style={{cursor: "pointer"}} >
                <img height="200px" width="400px" src={img1} alt="Survey Image" />
                </div>
                <p><small>Click to enlarge</small></p>
 
                <ListGroup className='tour-card-listgroup'>
                  <ListGroupItem>
                      <ListGroupItemHeading>{option1_listheader1}</ListGroupItemHeading>
                      <ListGroupItemText>{option1_listdetail1}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                      <ListGroupItemHeading>{option1_listheader2}</ListGroupItemHeading>
                      <ListGroupItemText>{option1_listdetail2}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                      <ListGroupItemHeading>{option1_listheader3}</ListGroupItemHeading>
                      <ListGroupItemText>{option1_listdetail3}</ListGroupItemText>
                  </ListGroupItem>
                  </ListGroup>
              
              </CardBody>

              </Card>
              
              <button className="btn btn-pill btn-lg btn-outline-dark" style={{marginBottom:"20px"}} onClick={() => this.toggleBest(1)}>{buttonname}</button>
              <span>&nbsp;&nbsp;</span>
            <button className="btn btn-pill btn-lg btn-outline-danger" style={{marginBottom:"20px"}} onClick={() => this.toggleWorst(1)}>{worstbutton}</button>
              
              </Col>

              <Col>

              <Card>
              
              <CardHeader className='survey-card-header'>{optionheader2}</CardHeader>
              
              <CardBody style={{backgroundColor: this.state.select2 }} >           
 
              <div style={{cursor: "pointer"}} >
                <GifPlayer
                    gif={gif2}
                    height="200px"
                    width="400px"
                    />
                </div>
                <p><small>Click to play</small></p>
 
                <ListGroup className='tour-card-listgroup'>
                  <ListGroupItem>
                      <ListGroupItemHeading>{option2_listheader1}</ListGroupItemHeading>
                      <ListGroupItemText>{option2_listdetail1}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                      <ListGroupItemHeading>{option2_listheader2}</ListGroupItemHeading>
                      <ListGroupItemText>{option2_listdetail2}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                      <ListGroupItemHeading>{option2_listheader3}</ListGroupItemHeading>
                      <ListGroupItemText>{option2_listdetail3}</ListGroupItemText>
                  </ListGroupItem>
                  </ListGroup>
              
              </CardBody>
              
              </Card>
              
              <button className="btn btn-pill btn-lg btn-outline-dark" style={{marginBottom:"20px"}} onClick={() => this.toggleBest(2)}>{buttonname}</button>
              <span>&nbsp;&nbsp;</span>
            <button className="btn btn-pill btn-lg btn-outline-danger" style={{marginBottom:"20px"}} onClick={() => this.toggleWorst(2)}>{worstbutton}</button>
              
              </Col>

              <Col>

              <Card>

              <CardHeader className='survey-card-header'>{optionheader3}</CardHeader>

              <CardBody style={{backgroundColor: this.state.select3 }} >        
 
              <div className='player-wrapper'>
                <ReactPlayer
                    url={vid3}
                    controls='true'
                    muted='true'
                    height="200px"
                    width="400px"
                    />
              </div>
              <p><small>Click to play</small></p>

                <ListGroup className='tour-card-listgroup'>
                  <ListGroupItem>
                      <ListGroupItemHeading>{option3_listheader1}</ListGroupItemHeading>
                      <ListGroupItemText>{option3_listdetail1}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                      <ListGroupItemHeading>{option3_listheader2}</ListGroupItemHeading>
                      <ListGroupItemText>{option3_listdetail2}</ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem>
                      <ListGroupItemHeading>{option3_listheader3}</ListGroupItemHeading>
                      <ListGroupItemText>{option3_listdetail3}</ListGroupItemText>
                  </ListGroupItem>
                  </ListGroup>

              </CardBody>

              </Card>
              <button className="btn btn-pill btn-lg btn-outline-dark" style={{marginBottom:"20px"}} onClick={() => this.toggleBest(3)}>{buttonname}</button>
              <span>&nbsp;&nbsp;</span>
            <button className="btn btn-pill btn-lg btn-outline-danger" style={{marginBottom:"20px"}} onClick={() => this.toggleWorst(3)}>{worstbutton}</button>

            </Col>

            </Row>

            </div>
            </div>
        </div>
    );
  }
}